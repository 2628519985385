// Material UI
import { Grid, Box, Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// Images
import requirements from "../../requirements.svg"
import browse from "../../browse.svg"
import relax from "../../relax.svg"

// styles
const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.up("xs")]: {
      maxWidth: "200px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "175px",
    },
  },
}))

export default function HowItWorks() {
  const classes = useStyles()

  return (
    <Container maxWidth="lg">
      <Box py={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1" align="center" gutterBottom={true}>
              Your dream location in 3 easy steps
            </Typography>
            <Box display="flex">
              <Box maxWidth="500px" className="mx-auto" mb={4}>
                <Typography color="textSecondary" align="center" variant="h5">
                  Not sure exactly what you want? No bother! Answer a few simple
                  questions and let us show you the places that will really feel
                  like home.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center" mb={4}>
              <img src={requirements} className={classes.image} />
            </Box>
            <Typography variant="h3" align="center">
              Your Requirements
            </Typography>
            <Box display="flex" mt={2}>
              <Box maxWidth="300px" className="mx-auto">
                <Typography color="textSecondary" align="center">
                  Start with our search tool, or talk to one of our relocation
                  experts and let us build an image of the life you’re dreaming
                  of back in the UK.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center" mb={4}>
              <img src={browse} className={classes.image} />
            </Box>
            <Typography variant="h3" align="center">
              Browse
            </Typography>
            <Box display="flex" mt={2}>
              <Box maxWidth="300px" className="mx-auto">
                <Typography color="textSecondary" align="center">
                  We’ll send you your bespoke relocation pack, with
                  recommendations on everything you requested
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center" mb={4}>
              <img src={relax} className={classes.image} />
            </Box>
            <Typography variant="h3" align="center">
              …and Relax
            </Typography>
            <Box display="flex" mt={2}>
              <Box maxWidth="300px" className="mx-auto">
                <Typography color="textSecondary" align="center">
                  Let us handle the stress. Sit back, safe in the knowledge that
                  WheresHome has taken the headache out of arranging your move
                  to the UK
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
