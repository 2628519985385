import { useState, useContext, useEffect, useRef } from "react"
// Router
import { useHistory, Redirect } from "react-router-dom"
import { Auth, Hub, UserContext } from "../../cognito"
// Material UI
import { Box, TextField, Typography, Link, Button } from "@material-ui/core"
import { makeStyles, Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
// Components
import { Block } from "Components"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    width: "100%"
  },
  innerWrapper: {
    width: "100%",
    maxWidth: "400px"
  }
}))

export default function ForgotPassword() {
  const classes = useStyles()
  const user = useContext(UserContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [code, setCode] = useState("")
  const [requested, setRequested] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("Authentication Failed")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user) {
      window.location = "/admin/locations"
    }
  }, [])

  async function confirmNewPassword() {
    setLoading(true)
    Auth.forgotPasswordSubmit(email, code, password)
      .then((data) => {
        Auth.signIn(email, password).then((response) => {
          window.location = "/admin/locations"
        })
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setSnackbarMsg(err.message)
        setSnackbarOpen(true)
      })
  }

  function requestCode() {
    Auth.forgotPassword(email)
      .then((data) => {
        setRequested(true)
      })
      .catch((err) => {
        console.log(err)
        setSnackbarMsg(err.message)
        setSnackbarOpen(true)
      })
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.wrapper}
      >
        <Box className={classes.innerWrapper}>
          <Box>
            <Typography variant="h3" align="center" paragraph={true}>
              Forgot password
            </Typography>
            <Box mb={2}>
              <TextField
                variant="outlined"
                label="Email"
                className={classes.fullWidth}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Box>
            {requested ? (
              <>
                <Box mb={2}>
                  <TextField
                    variant="outlined"
                    label="New Password"
                    className={classes.fullWidth}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    type="password"
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    variant="outlined"
                    label="Code"
                    className={classes.fullWidth}
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                    type="text"
                  />
                </Box>
                <Button
                  color="primary"
                  onClick={confirmNewPassword}
                  disabled={loading}
                  fullWidth={true}
                >
                  Change password
                </Button>
              </>
            ) : (
              <Button
                color="primary"
                onClick={requestCode}
                disabled={loading}
                fullWidth={true}
              >
                Request Code
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  )
}
