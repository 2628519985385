import { BLOG_CATEGORIES } from "../../constants"
import { useState, useEffect } from "react"
import axios from "axios"
// Router
import { useHistory } from "react-router-dom"
// Material UI
import { Grid, Box, Typography, Chip, Button, Paper } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { makeStyles } from "@material-ui/core/styles"
// Components
import { SinglePost } from "Components/User"

// styles
const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

export default function Blog() {
  const classes = useStyles()
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [nextPage, setNextPage] = useState("")
  const [noMore, setNoMore] = useState(false)
  const [category, setCategory] = useState()

  useEffect(() => {
    setLoading(true)
    const params = category ? { category } : {}
    axios.get("/blog/posts", { params }).then((response) => {
      setPosts(response.data.results)
      setLoading(false)
      setNextPage(response.data.next)
      setNoMore(!Boolean(response.data.next))
    })
  }, [category])

  function loadMoreResults() {
    setLoading(true)
    const params = category ? { category } : {}
    axios.get(nextPage, { params }).then((response) => {
      setPosts([...posts, ...response.data.results])
      setNextPage(response.data.next)
      setNoMore(!Boolean(response.data.next))
      setLoading(false)
    })
  }

  return (
    <Box pt={2} pb={8}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" align="center" gutterBottom={true}>
            Blog
          </Typography>
          <Box display="flex">
            <Box maxWidth="800px" className="mx-auto">
              <Typography color="textSecondary" align="center" variant="h5">
                Have a read of our insightful blogs from industry experts and
                our in-the-know team who cover the ins and outs of the
                relocation process as well as the ever-evolving UK property
                landscape.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            mb={4}
            mt={2}
          >
            {Object.entries(BLOG_CATEGORIES).map(([key, value]) => (
              <Chip
                label={value}
                clickable
                variant="outlined"
                className={classes.chip}
                color={category == key ? "primary" : ""}
                onClick={() => setCategory(category == key ? null : key)}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {posts.map((post) => (
          <SinglePost post={post} />
        ))}
        {loading && (
          <>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </>
        )}
      </Grid>
      {!noMore && (
        <Box display="flex" my={4}>
          <Button
            variant="outlined"
            color="secondary"
            className="mx-auto"
            onClick={loadMoreResults}
          >
            Load More Results
          </Button>
        </Box>
      )}
    </Box>
  )
}

function LoadingCard() {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper varian="outlined">
        <Skeleton variant="rect" height={175} />
        <Box p={2} pb={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                <Skeleton width={100} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <Skeleton width={110} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <Skeleton width={70} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={2}>
          <Skeleton variant="rect" height={36} />
        </Box>
      </Paper>
    </Grid>
  )
}
