import { useState, useContext } from "react"
// Material UI
import { AppBar, Box, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { RequestingContext } from "Contexts"

// styles
const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#FFF",
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "0px -3px 4px 0px rgba(0,0,0,0.07)",
    WebkitBoxShadow: "0px -3px 4px 0px rgba(0,0,0,0.07)"
  },
  cancel: {
    marginRight: theme.spacing(2)
  }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export function GlobalSnackbar({
  snackbarOpen,
  setSnackbarOpen,
  msg,
  severity
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert onClose={() => setSnackbarOpen(false)} severity={severity}>
        {msg}
      </Alert>
    </Snackbar>
  )
}

export default function SaveBar({ visible, handleSave, handleCancel }) {
  const classes = useStyles()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const { requesting, setRequesting } = useContext(RequestingContext.Context)

  if (!visible) {
    return <></>
  }

  if (requesting) {
    return <></>
  }

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Box display="flex" justifyContent="center" py={2}>
        <Button
          size="large"
          variant="outlined"
          color="default"
          className={classes.cancel}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={() => {
            handleSave()
            setSnackbarOpen(true)
          }}
        >
          Save
        </Button>
      </Box>
    </AppBar>
  )
}
