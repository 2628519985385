import { useState, useEffect } from "react"
import queryString from "query-string"
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
// Material UI
import {
  Grid,
  Box,
  Typography,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core"
// Components
import { ActiveFilters, LocationCard, LoadingCard } from "Components/User"

function clean(obj) {
  for (var propName in obj) {
    if (Boolean(obj[propName] == false)) {
      delete obj[propName]
    }
  }
  return obj
}

// translate frontend query string params to search options
function sanitize(queryStringParams) {
  const params = {
    orderBy: queryStringParams.orderBy || "",
    searchType: queryStringParams.searchType || "",
    searchBy: queryStringParams.searchBy || "",
    county: queryStringParams.county || "",
    region: queryStringParams.region || "",
    location: queryStringParams.location || "",
    locationName: queryStringParams.locationName || "",
    radius: queryStringParams.radius || "",
    bedrooms: queryStringParams.bedrooms || "",
    minSchoolRating: queryStringParams.schools || "",
    maxCrimeLevel: queryStringParams.crime || "",
    areaType: queryStringParams.areaType || "",
    amenities: queryStringParams.amenities || "",
    maxPricePerSqft: queryStringParams.maxSqf || "",
    maxPrice: queryStringParams.maxPrice || "",
  }

  if (params.location) {
    params.location = {
      id: params.location,
      name: params.locationName,
    }
    delete params["locationName"]
  }

  if (!["county", "region", "location"].includes(params.searchBy)) {
    params.searchBy = "county"
  }

  if (!["buy", "rent"].includes(params.searchType)) {
    params.searchType = "buy"
  }

  if (
    !["lowest", "highest", "sqf", "crime", "schools"].includes(params.orderBy)
  ) {
    params.orderBy = "lowest"
  }

  return params
}

export default function Search() {
  const history = useHistory()
  const location = useLocation()
  const queryStringParams = sanitize(queryString.parse(location.search))

  const [towns, setTowns] = useState([])
  const [total, setTotal] = useState()
  const [nextPage, setNextPage] = useState()
  const [noMore, setNoMore] = useState(false)
  const [loading, setLoading] = useState(true)
  const [searchOptions, setSearchOptions] = useState(queryStringParams)
  const [orderBy, setOrderBy] = useState(queryStringParams.orderBy)
  const buying = searchOptions.searchType == "buy"

  // translate seach options to params for backend
  function getParams() {
    const params = {}

    if (orderBy == "lowest") {
      const { bedrooms } = searchOptions
      params["ordering"] = `average_${buying ? "house_price" : "rent"}_${
        bedrooms ? bedrooms : "all"
      }_min`
    }
    if (orderBy == "highest") {
      const { bedrooms } = searchOptions
      params["ordering"] = `-average_${buying ? "house_price" : "rent"}_${
        bedrooms ? bedrooms : "all"
      }_min`
    }
    if (orderBy == "schools") {
      params["ordering"] = "-average_school_rating_number"
    }
    if (orderBy == "crime") {
      params["ordering"] = "average_crime_rating_number"
    }
    if (orderBy == "sqf") {
      params["ordering"] = "average_house_price_sqf_min"
    }

    if (searchOptions.county) {
      params["county"] = searchOptions.county
    }
    if (searchOptions.region) {
      params["region"] = searchOptions.region
    }
    if (searchOptions.location) {
      params["location"] = searchOptions.location.id
    }
    if (searchOptions.radius) {
      params["radius"] = searchOptions.radius
    }

    if (searchOptions.maxPrice) {
      if (searchOptions.bedrooms) {
        params[
          `average_${buying ? "house_price" : "rent"}_${
            searchOptions.bedrooms
          }_min__lte`
        ] = searchOptions.maxPrice
      } else {
        params[`average_${buying ? "house_price" : "rent"}_all_min__lte`] =
          searchOptions.maxPrice
      }
    }
    if (searchOptions.maxPricePerSqft) {
      params[`average_house_price_sqf_min__lte`] = searchOptions.maxPricePerSqft
    }
    if (searchOptions.maxCrimeLevel) {
      params[`average_crime_rating_number__lte`] = searchOptions.maxCrimeLevel
    }
    if (searchOptions.minSchoolRating) {
      params[`average_school_rating_number__gte`] =
        searchOptions.minSchoolRating
    }
    if (searchOptions.areaType) {
      params[`area_type`] = searchOptions.areaType
    }
    if (searchOptions.amenities) {
      params[`amenities`] = searchOptions.amenities
    }
    return params
  }

  // translate search options to frontend querystring
  function generateQueryString() {
    const params = {
      maxPrice: searchOptions.maxPrice,
      bedrooms: searchOptions.bedrooms,
      maxSqf: searchOptions.maxPricePerSqft,
      crime: searchOptions.maxCrimeLevel,
      schools: searchOptions.minSchoolRating,
      areaType: searchOptions.areaType,
      amenities: searchOptions.amenities,
    }
    if (searchOptions.searchType != "buy") {
      params.searchType = searchOptions.searchType
    }
    if (orderBy != "lowest") {
      params.orderBy = orderBy
    }
    if (searchOptions.searchBy == "county" && searchOptions.county) {
      params.searchBy = "county"
      params.county = searchOptions.county
    }
    if (searchOptions.searchBy == "region" && searchOptions.region) {
      params.searchBy = "region"
      params.region = searchOptions.region
    }
    if (
      searchOptions.searchBy == "location" &&
      searchOptions.location &&
      searchOptions.radius
    ) {
      params.searchBy = "location"
      params.location = searchOptions.location.id
      params.locationName = searchOptions.location.name
      params.radius = searchOptions.radius
    }
    return queryString.stringify(clean(params))
  }

  // initial fetch of search results
  useEffect(() => {
    setLoading(true)
    const queryString = generateQueryString()
    history.replace("/search?" + queryString)
    axios.get("/locations/towns", { params: getParams() }).then((response) => {
      setTowns(response.data.results)
      setNextPage(response.data.next)
      setTotal(response.data.count)
      setNoMore(!Boolean(response.data.next))
      setLoading(false)
    })
  }, [searchOptions, orderBy])

  function loadMoreResults() {
    setLoading(true)
    axios.get(nextPage, { params: getParams() }).then((response) => {
      setTowns([...towns, ...response.data.results])
      setNextPage(response.data.next)
      setTotal(response.data.count)
      setNoMore(!Boolean(response.data.next))
      setLoading(false)
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography>
              {loading ? (
                "Fetching results..."
              ) : (
                <>
                  Showing {towns.length} of {total} results
                </>
              )}
            </Typography>
            <Box maxWidth="200px" width="100%" className="ml-auto">
              <TextField
                label="Sort By"
                select
                value={orderBy}
                onChange={(event) => setOrderBy(event.target.value)}
              >
                <MenuItem value="lowest">Lowest price</MenuItem>
                <MenuItem value="highest">Highest price</MenuItem>
                <MenuItem value="sqf">Price per sqft</MenuItem>
                <MenuItem value="crime">Crime</MenuItem>
                <MenuItem value="schools">Schools</MenuItem>
              </TextField>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ActiveFilters
            buying={buying}
            searchOptions={searchOptions}
            search={(newOptions) =>
              setSearchOptions({
                searchType: searchOptions.searchType,
                ...newOptions,
              })
            }
          />
        </Grid>
        {towns.map((town) => (
          <LocationCard
            key={town.id}
            bedrooms={searchOptions.bedrooms}
            town={town}
            buying={buying}
          />
        ))}
        {loading && (
          <>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </>
        )}
        {!loading && towns.length == 0 && (
          <div style={{ textAlign: "center", width: "100%", padding: "20px" }}>
            There are no results for this search
          </div>
        )}
      </Grid>
      {!noMore && (
        <Box display="flex" my={4}>
          <Button
            variant="outlined"
            color="secondary"
            className="mx-auto"
            onClick={loadMoreResults}
          >
            Load More Results
          </Button>
        </Box>
      )}
    </>
  )
}
