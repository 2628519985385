import { useLocation } from "react-router-dom"
// Material UI
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// ReactShare
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share"

// styles
const useStyles = makeStyles((theme) => ({
  shareWrapper: {
    marginBottom: "50px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "20px"
    },
    "& button": {
      marginRight: "8px"
    }
  }
}))

export default function ShareLocation() {
  const classes = useStyles()

  // Share content
  const shareUrl = window.location.href
  const title = "Check out this awesome location I found on WheresHome!"
  const summary =
    "I was using WheresHome.co.uk to find a great place to live in the UK and came across this:"
  const source = "WheresHome.co.uk"
  const hashtags = ["WheresHome"]

  return (
    <Box mt={2} className={classes.shareWrapper}>
      <Typography gutterBottom={true} color="textSecondary">
        Share this location:
      </Typography>
      <EmailShareButton url={shareUrl} subject={title} body={summary}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
      <FacebookShareButton url={shareUrl} quote={title} hashtags={hashtags}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <LinkedinShareButton
        url={shareUrl}
        title={title}
        summary={summary}
        source={source}
      >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      <TwitterShareButton url={shareUrl} title={title} hashtags={hashtags}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton url={shareUrl} title={title}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
    </Box>
  )
}
