import React, { useState, useEffect } from "react"
import axios from "axios"
// Router
import { useHistory } from "react-router-dom"
// Material UI
import {
  Grid,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Link,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// Logo
import logoWhite from "../../logo-white.svg"
import CookieConsent from "react-cookie-consent"
import { SocialIcon } from "react-social-icons"

// styles
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    color: "#FFF",
    "& .MuiContainer-root": {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
  },
  link: {
    color: "#FFF",
    lineHeight: 1.5,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  trustpilot: {
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      right: "55px",
    },
    [theme.breakpoints.up("md")]: {
      right: "25px",
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  const history = useHistory()
  const [towns, setTowns] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios.get("/locations/towns/?favorite=true").then((response) => {
      setTowns(response.data.results)
      setLoading(false)
    })
  }, [])

  return (
    <div className={classes.container}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box mb={2}>
              <img src={logoWhite} alt="Logo" height="40" />
            </Box>
            <Typography style={{ lineHeight: 1.5, marginBottom: 6 }}>
              © Whereshome.co.uk 2021
            </Typography>
            <a
              href="https://whereshome-storage-2.s3.us-west-2.amazonaws.com/Whereshome+Privacy+Policy+July+2021.pdf"
              target="_blank"
              className={classes.link}
            >
              Privacy Policy
            </a>
            <Box mt={3}>
              <SocialIcon
                url="https://m.facebook.com/relocationexperts/?ref=py_c"
                fgColor="#fff"
                style={{ width: 40, height: 40, marginRight: 10 }}
              />
              <SocialIcon
                url="https://www.instagram.com/whereshome.co.uk/"
                fgColor="#fff"
                style={{ width: 40, height: 40, marginRight: 10 }}
              />
              <SocialIcon
                url="https://www.linkedin.com/in/whereshome-ltd-9a1b54210/?trk=public_profile_browsemap&originalSubdomain=ae"
                fgColor="#fff"
                style={{ width: 40, height: 40, marginRight: 10 }}
              />
              <SocialIcon
                url="https://twitter.com/Whereshomeuk"
                fgColor="#fff"
                style={{ width: 40, height: 40, marginRight: 10 }}
              />
            </Box>
            <Box mt={3} className={classes.trustpilot}>
              <div
                class="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="5419b6a8b0d04a076446a9ad"
                data-businessunit-id="6149696c597672001d1690b5"
                data-style-height="24px"
                data-style-width="100%"
                data-theme="dark"
                data-min-review-count="10"
              >
                <a
                  href="https://uk.trustpilot.com/review/whereshome.co.uk"
                  target="_blank"
                  rel="noopener"
                >
                  Trustpilot
                </a>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3">Explore</Typography>
            <List dense={true}>
              <FooterListItem path="/search" text="Find WheresHome" />
              <FooterListItem path="/about" text="About us" />
              <FooterListItem path="/services" text="What We Offer" />
              <FooterListItem path="/blog" text="Blog" />
              <FooterListItem path="/contact" text="Contact us" />
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3">Favourites</Typography>
            <List dense={true}>
              {towns.map((town) => (
                <FooterListItem
                  path={`/location/${town.id}`}
                  text={town.name}
                />
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3" style={{ marginBottom: "12px" }}>
              About us
            </Typography>
            <Typography>
              Whereshome was founded in 2021 and helps people find the home of
              their dreams in the locations of their dreams. Once we help you
              find a place that feels like home via our seamless search tool,
              we’ll connect you with local services to take care of the next
              steps, or help you ourselves with our dedicated concierge service.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}

function FooterListItem({ path, text }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <ListItem disableGutters={true}>
      <Link onClick={() => history.push(path)} className={classes.link}>
        {text}
      </Link>
    </ListItem>
  )
}
