export const COUNTIES = [
  "Aberdeenshire",
  "Anglesey",
  "Angus",
  "Argyllshire",
  "Ayrshire",
  "Banffshire",
  "Bedfordshire",
  "Berkshire",
  "Berwickshire",
  "Brecknockshire",
  "Buckinghamshire",
  "Buteshire",
  "Caernarfonshire",
  "Caithness",
  "Cambridgeshire",
  "Cardiganshire",
  "Carmarthenshire",
  "Cheshire",
  "Clackmannanshire",
  "Cornwall",
  "County Antrim",
  "County Armagh",
  "County Down",
  "County Durham",
  "County Fermanagh",
  "County Londonderry",
  "County Tyrone",
  "Cumberland",
  "Denbighshire",
  "Derbyshire",
  "Devon",
  "Dorset",
  "Dumfriesshire",
  "Dunbartonshire",
  "East Lothian",
  "Essex",
  "Fife",
  "Flintshire",
  "Glamorgan",
  "Gloucestershire",
  "Greater London",
  "Gwynedd",
  "Hampshire",
  "Herefordshire",
  "Hertfordshire",
  "Huntingdonshire",
  "Inverness-shire",
  "Isle of Islay",
  "Isle of Lewis",
  "Isle of Skye",
  "Isle of Wight",
  "Isles of Scilly",
  "Kent",
  "Kincardineshire",
  "Kinross-Shire",
  "Kirkcudbrightshire",
  "Lanarkshire",
  "Lancashire",
  "Leicestershire",
  "Lincolnshire",
  "Liverpool",
  "London",
  "Manchester",
  "Merioneth",
  "Middlesex",
  "Midlothian",
  "Monmouthshire",
  "Montgomeryshire",
  "Morayshire",
  "Nairnshire",
  "Norfolk",
  "Northamptonshire",
  "Northumberland",
  "Nottinghamshire",
  "Orkney",
  "Oxfordshire",
  "Peeblesshire",
  "Pembrokeshire",
  "Perthshire",
  "Radnorshire",
  "Renfrewshire",
  "Ross-shire",
  "Roxburghshire",
  "Rutland",
  "Selkirkshire",
  "Shetland",
  "Shropshire",
  "Somerset",
  "Staffordshire",
  "Stirlingshire",
  "Suffolk",
  "Surrey",
  "Sussex",
  "Sutherland",
  "Warwickshire",
  "West Lothian",
  "Westmorland",
  "Wigtownshire",
  "Wiltshire",
  "Worcestershire",
  "Yorkshire "
]

export const REGIONS = [
  "East Midlands",
  "East of England",
  "Greater London",
  "London",
  "North East",
  "North West",
  "Northern Ireland",
  "Scotland",
  "South East",
  "South West",
  "Wales",
  "West Midlands",
  "Yorkshire and the Humber"
]

export const SCHOOL_RATINGS = {
  1: "Poor schools",
  2: "Average schools",
  3: "Good schools",
  4: "Very good schools"
}

export const CRIME_RATINGS = {
  1: "Very low crime",
  2: "Low crime",
  3: "Average crime",
  4: "High crime",
  5: "Very high crime"
}

export const BLOG_CATEGORIES = {
  1: "Area Guides",
  2: "For Expats",
  3: "UK Relocators",
  4: "Resources"
}
