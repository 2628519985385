import { BLOG_CATEGORIES } from "../../constants"
import React, { useState, useEffect } from "react"
import { format } from "date-fns"
import { useHistory, useParams } from "react-router-dom"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, EditorState, convertToRaw } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { AsyncAutoComplete } from "Components/Generic"
import axios from "axios"

// Material UI
import {
  Grid,
  Paper,
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// styles
const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    height: "auto",
  },
}))

export default function ViewPost() {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")
  const [category, setCategory] = useState("")
  const [summary, setSummary] = useState("")
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"))
  const [image, setImage] = useState("")
  const [content, setContent] = useState(EditorState.createEmpty())
  const [seoTitle, setSeoTitle] = useState("")
  const [seoDescription, setSeoDescription] = useState("")
  const [linkedLocation, setLinkedLocation] = useState()
  const [loading, setLoading] = useState(Boolean(id))

  function sanitizeUrl(url) {
    setUrl(url.replace(/[^0-9a-z\-]/gi, ""))
  }

  useEffect(() => {
    if (!id) return

    axios.get(`/blog/posts/${id}/`).then(({ data }) => {
      let editorState = EditorState.createEmpty()
      if (data.content) {
        const { contentBlocks, entityMap } = htmlToDraft(data.content)
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        )
        editorState = EditorState.createWithContent(contentState)
      }

      setTitle(data.title)
      setCategory(data.category)
      setSummary(data.summary)
      setUrl(data.url)
      setDate(format(new Date(data.date), "yyyy-MM-dd"))
      if (data.featuredImage) setImage(data.featuredImage.url)
      setSeoTitle(data.seoTitle)
      setContent(editorState)
      setSeoDescription(data.seoDescription)
      setLinkedLocation(data.linkedLocation)
      setLoading(false)
    })
  }, [id])

  async function savePost() {
    // convert rich text to html
    const raw = convertToRaw(content.getCurrentContent())
    const markup = draftToHtml(raw)

    const data = {
      title,
      category,
      url,
      date: date,
      content: markup,
      summary,
      seoTitle,
      seoDescription,
      linkedLocation: linkedLocation ? linkedLocation.id : null,
    }

    // upload image
    if (image.startsWith("blob:")) {
      let formData = new FormData()
      let blob = await fetch(image).then((r) => r.blob())
      formData.append("url", blob, "image." + blob.type.split("/")[1])
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }

      const response = await axios.post("/blog/images/", formData, options)
      data.featuredImage = response.data.id
    }

    if (id) {
      axios.patch(`/blog/posts/${id}/`, data)
    } else {
      const response = await axios.post(`/blog/posts/`, data)
    }
  }

  async function onChangeHandler(event) {
    const file = event.target.files[0]
    const url = URL.createObjectURL(file)
    setImage(url)
  }

  if (loading) return "loading..."

  return (
    <>
      <Typography variant="h2" paragraph>
        Blog Post
      </Typography>
      <Box mb={3}>
        <Paper>
          <Box p={2}>
            <Typography variant="h4" paragraph>
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  label="Title"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Category"
                  select
                  value={category}
                  onChange={(event) => setCategory(event.target.value)}
                >
                  {Object.entries(BLOG_CATEGORIES).map(([key, value]) => (
                    <MenuItem value={key}>{value}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={url}
                  onChange={(event) => sanitizeUrl(event.target.value)}
                  label="URL"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Date"
                  type="date"
                  value={format(new Date(date), "yyyy-MM-dd")}
                  onChange={(event) => setDate(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Summary"
                  type="text"
                  value={summary}
                  onChange={(event) => setSummary(event.target.value)}
                />
              </Grid>
            </Grid>
            <Box maxWidth="300px" mt={2}>
              <img src={image} alt="" className={classes.img} />
              <Box display="flex" mt={1} className="mx-auto">
                <input type="file" name="file" onChange={onChangeHandler} />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mb={3}>
        <Paper>
          <Box p={2}>
            <Typography variant="h4" paragraph>
              Content
            </Typography>
            <Box
              style={{
                border: "1px solid grey",
                minHeight: "6em",
                cursor: "text",
              }}
            >
              <Editor
                editorState={content}
                onEditorStateChange={setContent}
                toolbar={{
                  options: ["inline", "blockType", "list", "image", "link"],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                  list: {
                    inDropdown: false,
                    options: ["unordered", "ordered"],
                  },
                  link: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    dropdownClassName: undefined,
                    showOpenOptionOnHover: true,
                    defaultTargetOption: "_self",
                    options: ["link"],
                    linkCallback: undefined,
                  },
                  textAlign: { inDropdown: true },
                  image: {
                    previewImage: true,
                    alt: { present: true, mandatory: true },
                    uploadEnabled: true,
                    uploadCallback: (file) => {
                      let formData = new FormData()
                      formData.append("url", file, file.name)
                      const options = {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }

                      return axios
                        .post("/blog/images/", formData, options)
                        .then((response) => {
                          const link = response.data.url.split("?")[0]
                          return {
                            data: { link },
                          }
                        })
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mb={3}>
        <Paper>
          <Box p={2}>
            <Typography variant="h4" paragraph>
              SEO
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={seoTitle}
                  onChange={(event) => setSeoTitle(event.target.value)}
                  label="Title"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={seoDescription}
                  onChange={(event) => setSeoDescription(event.target.value)}
                  label="Description"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <Box mb={3}>
        <Paper>
          <Box p={2}>
            <Typography variant="h4" paragraph>
              Linked Location
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AsyncAutoComplete
                  value={linkedLocation}
                  url="/locations/towns/"
                  label="Search location..."
                  onChange={(value) => {
                    setLinkedLocation(value)
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <Box mb={5}>
        <Button onClick={savePost}>
          {Boolean(id) ? "Save Changes" : "Save"}
        </Button>
      </Box>
    </>
  )
}
