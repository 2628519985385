import React from "react"
import axios from "axios"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useDebouncedState } from "utilities"

export default function Asynchronous({ label, url, onChange, value, variant='outlined' }) {
  const [options, setOptions] = React.useState([value])
  const [searchText, setSearchText, debouncedSearchText, waiting] =
    useDebouncedState("", 250)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    axios.get(`${url}?search=${debouncedSearchText}`).then((response) => {
      const results = response.data.results.map((result) => ({
        id: result.id,
        name: result.name,
      }))
      setOptions(results)
      setLoading(false)
    })
  }, [debouncedSearchText])

  return (
    <Autocomplete
      value={value}
      getOptionSelected={(option, value) => {
        return option ? option.id === value.id : false
      }}
      getOptionLabel={(option) => (option ? option.name : "")}
      options={options}
      loading={loading || waiting}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
