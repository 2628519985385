import Amplify, { Auth, Hub } from "aws-amplify"
import { createContext } from "react"

const config = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id:
    "us-west-2:c4eef060-d145-46cf-add3-47693892775c",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_xGyMuLcu7",
  aws_user_pools_web_client_id: "6tvcfj47i4go25cdcdb2q8hq7f",
  oauth: {},
}

Amplify.configure(config)

const UserContext = createContext()

export { Auth, Hub, UserContext }
