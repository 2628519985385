import { useEffect } from "react"

// Router
import { useHistory } from "react-router-dom"
// Material UI
import { Grid, Box, Typography } from "@material-ui/core"
// Components
import { ContactForm } from "Components/User"

export default function Contact() {
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box pt={2} pb={8}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" align="center" gutterBottom={true}>
            Contact Us
          </Typography>
          <Box display="flex">
            <Box maxWidth="800px" className="mx-auto">
              <Typography color="textSecondary" align="center" variant="h5">
                Contact us via the form below and one of our expert relocation
                consultants will get in touch with you within 24 hours.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ContactForm />
    </Box>
  )
}
