// Router
import { useHistory } from "react-router-dom"
// Material UI
import { Paper, Box, Container, Typography } from "@material-ui/core"
// Components
import { ContactForm } from "Components/User"

export default function GetInTouch() {
  return (
    <Container maxWidth="lg">
      <Box pt={10} pb={15} display="flex" justifyContent="center">
        <Paper style={{ width: "100%", maxWidth: "500px" }}>
          <Box p={4}>
            <Typography variant="h1" align="center" gutterBottom={true}>
              Get in touch
            </Typography>
            <Box display="flex">
              <Box className="mx-auto">
                <Typography color="textSecondary" align="center" variant="h5">
                  Contact us via the form below and one of our friendly team
                  members will get back to you within a few hours.
                </Typography>
              </Box>
            </Box>
            <ContactForm />
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}
