import axios from "axios"
import { useState, useEffect } from "react"
// Material UI
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  Breadcrumbs,
} from "@material-ui/core"
import { SinglePost } from "Components/User"

export default function RelatedPosts({ town }) {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    axios
      .get(`/blog/posts/?linked_location=${town.id}`)
      .then((response) => setPosts(response.data.results))
  }, [])

  if (posts.length == 0) return ""

  return (
    <Box mb={6}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">Related Posts</Typography>
        </Grid>
        {posts.map((post) => (
          <SinglePost post={post} />
        ))}
      </Grid>
    </Box>
  )
}
