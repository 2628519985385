import { useState } from "react"
import axios from "axios"
// Material UI
import { Box, Button, TextField } from "@material-ui/core"

export default function ContactForm() {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [message, setMessage] = useState("")

  async function submit() {
    const data = {
      fullName,
      email,
      phoneNumber,
      message
    }
    await axios.post("/forms/contact/", data)
    setFullName("")
    setEmail("")
    setPhoneNumber("")
    setMessage("")
  }

  return (
    <>
      <Box display="flex" justifyContent="center" mt={5}>
        <Box width="100%" maxWidth="400px">
          <Box mb={2}>
            <TextField
              label="Full name"
              type="text"
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Email address"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Phone number"
              type="tel"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Message"
              multiline
              rows="4"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button onClick={submit}>Send Message</Button>
      </Box>
    </>
  )
}
