import React, { useState, useEffect, useContext } from "react"
import MetaTags from "react-meta-tags"
import { Auth, Hub, UserContext } from "./cognito"
import { RequestingContext } from "Contexts"
import { theme } from "theme"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import axios from "axios"
import { capitalizeFirstLetter } from "./utilities"
import "./App.css"

// Material UI
import { Box } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"

// Components
import { Loading, GlobalSnackbar } from "Components/Generic"
import { UserFrame } from "Components/User"
import { AdminFrame } from "Components/Admin"
import ReactGA from "react-ga4"
import { createBrowserHistory } from "history"

// Pages
import {
  Home,
  Search,
  Location,
  About,
  Services,
  Blog,
  Post,
  Contact,
} from "Pages/User"
import {
  Login,
  ForgotPassword,
  Locations,
  EditLocation,
  Posts,
  EditPost,
} from "Pages/Admin"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

if (window.location.hostname != "localhost") {
  Sentry.init({
    dsn: "https://0d9819d9f69a4e2382b7d2216b9cdea4@o999744.ingest.sentry.io/5983310",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })

  ReactGA.initialize("UA-196812038-1")
  const browserHistory = createBrowserHistory()
  browserHistory.listen((location, action) => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: location.pathname + location.search,
    })
  })
}

// Auth
function App() {
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")
  const { setRequesting } = useContext(RequestingContext.Context)

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname + window.location.search,
    })
  }, [])

  useEffect(() => {
    Hub.listen("auth", async ({ payload }) => {
      switch (payload.event) {
        case "signIn":
          const user = await Auth.currentAuthenticatedUser()
          setCurrentUser(user)
          break
        case "signOut":
          setCurrentUser()
          break
      }
    })

    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCurrentUser(user)
        setLoading(false)
      })
      .catch((err) => {
        console.warn(err)
        setLoading(false)
      })

    // API Config
    axios.interceptors.request.use(async (config) => {
      setRequesting((prevRequesting) => prevRequesting + 1)
      if (window.location.hostname === "localhost") {
        config.baseURL = "http://localhost:8000/api"
      } else {
        config.baseURL = "https://api-london.whereshome.co.uk/api"
      }
      try {
        const session = await Auth.currentSession()
        const {
          accessToken: { jwtToken },
        } = session
        config.headers.common["Authorization"] = jwtToken
      } catch {}
      return config
    })
    axios.interceptors.response.use(
      (response) => {
        setTimeout(function () {
          setRequesting((prevRequesting) => prevRequesting - 1)
        })

        if (response.config.method != "get") {
          if (response.config.method === "delete") {
            setSnackbarMsg("Deleted")
          } else {
            setSnackbarMsg("Saved")
          }
          setSnackbarSeverity("success")
          setSnackbarOpen(true)
        }
        return response
      },
      (error) => {
        console.warn(error)
        setTimeout(function () {
          setRequesting((prevRequesting) => prevRequesting - 1)
        })
        if ("response" in error) {
          console.log({ error })
          if (error.response.config.method === "delete") {
            setSnackbarMsg("Delete failed")
          } else {
            if (error.response.status === 400) {
              let msgs = []
              for (let field in error.response.data) {
                msgs.push(
                  `${capitalizeFirstLetter(field)} - ${
                    error.response.data[field]
                  }`
                )
              }
              setSnackbarMsg(msgs.join("\n"))
            }
          }
        }
        setSnackbarSeverity("error")
        setSnackbarOpen(true)
      }
    )
  }, [])

  return (
    <UserContext.Provider value={currentUser}>
      <ThemeProvider theme={theme}>
        <GlobalSnackbar
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          severity={snackbarSeverity}
          msg={snackbarMsg}
        />
        <MetaTags>
          <title>
            Whereshome - Find your dream location, the key to your dream home
          </title>
          <meta
            name="description"
            content="Whereshome helps you find your perfect place to live in the UK."
          />
        </MetaTags>
        {loading ? <Loading /> : <Routes />}
      </ThemeProvider>
    </UserContext.Provider>
  )
}

// Routes
function Routes() {
  const user = useContext(UserContext)
  return (
    <Router>
      <>
        <Switch>
          <CustomRoute path="/" exact>
            <UserFrame home>
              <Home />
            </UserFrame>
          </CustomRoute>
          <CustomRoute path="/login">
            <Login />
          </CustomRoute>
          <CustomRoute path="/forgot-password">
            <ForgotPassword />
          </CustomRoute>
          <CustomRoute path="/search">
            <UserFrame maxWidth="lg">
              <Search />
            </UserFrame>
          </CustomRoute>
          <CustomRoute path="/location/:id">
            <UserFrame maxWidth="md">
              <Location />
            </UserFrame>
          </CustomRoute>
          <CustomRoute path="/about">
            <UserFrame maxWidth="lg">
              <About />
            </UserFrame>
          </CustomRoute>
          <CustomRoute path="/services">
            <UserFrame maxWidth="lg">
              <Services />
            </UserFrame>
          </CustomRoute>
          <Route path="/blog/post/:id">
            <UserFrame maxWidth="sm">
              <Post />
            </UserFrame>
          </Route>
          <CustomRoute path="/blog">
            <UserFrame maxWidth="lg">
              <Blog />
            </UserFrame>
          </CustomRoute>
          <CustomRoute path="/contact">
            <UserFrame maxWidth="lg">
              <Contact />
            </UserFrame>
          </CustomRoute>
          <CustomRoute path="/admin" exact>
            <Redirect to="/admin/locations" />
          </CustomRoute>
          <PrivateRoute path="/admin/locations">
            <AdminFrame maxWidth="lg">
              <Locations />
            </AdminFrame>
          </PrivateRoute>
          <PrivateRoute path="/admin/location/:id">
            <AdminFrame maxWidth="lg">
              <EditLocation />
            </AdminFrame>
          </PrivateRoute>
          <PrivateRoute path="/admin/posts">
            <AdminFrame maxWidth="lg">
              <Posts />
            </AdminFrame>
          </PrivateRoute>
          <PrivateRoute path="/admin/post/:id">
            <AdminFrame maxWidth="lg">
              <EditPost />
            </AdminFrame>
          </PrivateRoute>
          <PrivateRoute path="/admin/post">
            <AdminFrame maxWidth="lg">
              <EditPost />
            </AdminFrame>
          </PrivateRoute>
          <CustomRoute path="/404">
            <NotFound />
          </CustomRoute>
          <Redirect to="/404" />
        </Switch>
      </>
    </Router>
  )
}

function DefaultMetaTags() {
  return (
    <MetaTags>
      <title>Whereshome</title>
      <meta
        name="description"
        content="Whereshome helps you find your perfect place to live in the UK."
      />
    </MetaTags>
  )
}

function CustomRoute({ children, ...rest }) {
  return (
    <Route {...rest}>
      <DefaultMetaTags />
      {children}
    </Route>
  )
}

// 404
function NotFound() {
  return <Box p={3}>Page not found</Box>
}

// Private route
function PrivateRoute({ children, ...rest }) {
  const user = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return children
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}

export default App
