// Router
import { useHistory } from "react-router-dom"
// Material UI
import { Grid, Box, Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// Images
import about from "../../about.jpg"
import about3 from "../../about3.jpg"

// styles
const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: "cover",
    width: "100%",
    height: "350px",
  },
  order: {
    [theme.breakpoints.down("sm")]: {
      order: "2",
    },
  },
}))

export default function About() {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Box py={2}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" align="center" gutterBottom={true}>
            About Us
          </Typography>
          <Box display="flex">
            <Box maxWidth="800px" className="mx-auto">
              <Typography color="textSecondary" align="center" variant="h5">
                Moving house let alone country is definitely up there with one
                of most stressful events in one’s life. That’s why Whereshome
                has been created as a reliable, straightforward, effortless, and
                genuinely helpful platform. Our aim is to streamline the entire
                home hunting and relocation process.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <img src={about} alt="About Whereshome" className={classes.img} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" paragraph>
            Meet Stephan and Nadine Sowah, the founders of Whereshome.co.uk
          </Typography>
          <Typography color="textSecondary" paragraph>
            “We created this platform out of necessity when we were in the
            process of moving back to the UK after years abroad. We would spend
            days on end banging our heads against the wall trying to navigate
            and squeeze some useful, tangible, and personal information out of
            countless area guides and stats pages.
          </Typography>

          <Typography color="textSecondary" paragraph>
            As two thirtysomething parents to three gorgeous toddlers living in
            the UAE, this was our daily burden for months when planning the
            inevitable return to the UK, our previous home. With so much having
            changed for us as a family and for the place we once called home,
            the search to find our new happy place was far more complicated than
            we thought possible.
          </Typography>

          <Typography color="textSecondary" paragraph>
            These stressful few months were the inspiration behind Whereshome.
            We wanted to create a super simple, jargon-free, relatable, and
            supportive resource that was an absolute breeze to use for busy
            professionals and parents just like us, and we think we’ve hit the
            nail on the head!”
          </Typography>
          <Button
            style={{ marginTop: "16px" }}
            onClick={() => history.push("/services/")}
          >
            Our services
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6} className={classes.order}>
          <Typography variant="h4" paragraph>
            Simplifying the home location hunt and moving process
          </Typography>
          <Typography color="textSecondary" paragraph>
            As you can see, WheresHome was created out of necessity. The aim
            from the very beginning has been to avoid stress for our clients
            wherever possible and we’ve been achieving this with our own
            knowledge, care and attention to detail. But where we feel we need
            an expert eye, we have a ready and waiting network of tried and
            tested partners to help. We can offer expert advice on any area of
            your move and our partners include;
          </Typography>
          <Typography color="textSecondary">
            <ul>
              <li>
                A wide range of estate agents across the UK, so that we have the
                earliest access to properties as they come to the market
              </li>

              <li>
                Mortgages: for the best rates and advice on Expat and Domestic
                mortgages
              </li>

              <li>
                Car Hire: For exclusive rates and perks for WheresHome clients
              </li>

              <li>
                International Shipping: for the best deals and expert support
                for shipping your personal belongings
              </li>

              <li>
                Interior designers, to help you with your build, renovation or
                dressing your rental property over in the UK
              </li>
            </ul>
          </Typography>
          <Button
            style={{ marginTop: "16px" }}
            onClick={() => history.push("/")}
          >
            Get started now
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={about3}
            alt="Helping you find your perfect home"
            className={classes.img}
            style={{ height: 500 }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
