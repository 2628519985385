import React from "react"
// Components
import { HomeSearch, HowItWorks, Favourites, GetInTouch } from "Components/User"
import { makeStyles } from "@material-ui/core/styles"

export default function Home() {
  const { innerWidth: width, innerHeight: height } = window

  const videoWidth = width > 720 ? width / 2 : width

  return (
    <>
      <HomeSearch />
      <HowItWorks />
      <Favourites />
      <GetInTouch />
    </>
  )
}
