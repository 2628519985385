import { BLOG_CATEGORIES } from "../../constants"
import { format } from "date-fns"
// Router
import { useHistory } from "react-router-dom"
// Material UI
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  Breadcrumbs,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ArrowForward as ArrowIcon } from "@material-ui/icons/"

// styles
const useStyles = makeStyles((theme) => ({
  paper: {
    cursor: "pointer",
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "150px",
  },
  summaryBox: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 3,
    overflow: "hidden",
  },
}))

export default function SinglePost({ post }) {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper
        varian="outlined"
        onClick={() =>
          history.push(`/blog/post/${post.url ? post.url : post.id}`)
        }
        className={classes.paper}
      >
        {post.featuredImage ? (
          <img
            src={post.featuredImage.url}
            alt={post.title}
            className={classes.img}
          />
        ) : (
          <img
            src="https://i.stack.imgur.com/y9DpT.jpg"
            alt={post.name}
            className={classes.img}
          />
        )}
        <Box p={2} pb={0}>
          <Typography variant="h4" gutterBottom={true}>
            {post.title}
          </Typography>
          <Breadcrumbs>
            <Typography color="textSecondary">
              {format(new Date(post.date), "dd MMM yyyy")}
            </Typography>
            <Typography color="textSecondary">
              {BLOG_CATEGORIES[post.category]}
            </Typography>
          </Breadcrumbs>
          <Box mt={2} className={classes.summaryBox}>
            {post.summary}
          </Box>
        </Box>
        <Box display="flex" p={2}>
          <Button variant="text" className="ml-auto" endIcon={<ArrowIcon />}>
            Read more
          </Button>
        </Box>
      </Paper>
    </Grid>
  )
}
