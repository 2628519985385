import { Auth } from "../../cognito"
import { useState, useContext } from "react"
// Router
import { useHistory, useLocation } from "react-router-dom"
// Material UI
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Hidden,
  Drawer,
  List,
  ListItem,
  Container
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Menu as MenuIcon, Close as CloseIcon } from "@material-ui/icons"
// Logo
import logoBlack from "../../logo-black.svg"

// styles
const drawerWidth = 280
const topFix = 80

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    overflowX: "clip"
  },
  toolbar: {
    display: "flex",
    width: "100%"
  },
  clickableLogo: {
    cursor: "pointer"
  },
  appBar: {
    background: "#FFF",
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: "1px solid rgb(236, 239, 241)",
    borderRight: "none",
    boxShadow: "rgb(17 51 83 / 2%) 0px 4px 12px 0px"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: topFix,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "100%",
    maxWidth: "100%",
    flexShrink: 1,
    boxSizing: "border-box"
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      fontSize: "1rem",
      "& .MuiListItem-root": {
        paddingTop: "12px",
        paddingBottom: "12px"
      }
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiButtonBase-root": {
        display: "inline"
      }
    }
  },
  navText: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
    fontWeight: "600",
    fontSize: "1rem"
  },
  highlight: {
    fontWeight: "700"
  }
}))

export default function AdminFrame(props) {
  const history = useHistory()
  const classes = useStyles()
  const { window } = props
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <>
      <div className={classes.root}>
        <AppBar color="default" className={classes.appBar}>
          <Box display="flex" alignItems="center" p={2}>
            <Toolbar variant="dense" className={classes.toolbar}>
              <Box flexGrow={1}>
                <img
                  src={logoBlack}
                  alt="Logo"
                  height="40"
                  className={classes.clickableLogo}
                  onClick={() => history.push("/")}
                />
              </Box>
              <Hidden mdUp implementation="css">
                <IconButton
                  color="secondary"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Hidden smDown implementation="css">
                <NavList />
              </Hidden>
            </Toolbar>
          </Box>
        </AppBar>
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClick={handleDrawerToggle}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <Box display="flex" px={2}>
            <IconButton
              color="secondary"
              className="ml-auto"
              onClick={handleDrawerToggle}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <NavList />
        </Drawer>
        <Box className={classes.content}>
          <Container maxWidth={props.maxWidth}>{props.children}</Container>
        </Box>
      </div>
    </>
  )
}

// Components for links in drawer

function NavList() {
  const classes = useStyles()

  return (
    <List className={classes.list}>
      <NavListItem
        text="Locations"
        selectPath="locations"
        path="/admin/locations/"
      />
      <NavListItem text="Posts" selectPath="posts" path="/admin/posts/" />
      <Button
        variant="outlined"
        style={{ marginLeft: "20px" }}
        onClick={async () => {
          Auth.signOut()
          window.location = "/login"
        }}
      >
        Sign out{" "}
      </Button>
    </List>
  )
}

function NavListItem({ text, selectPath, path }) {
  const history = useHistory()
  const { pathname } = useLocation()
  const selected = pathname.split("/")[1] == selectPath
  const classes = useStyles()

  return (
    <ListItem
      button
      className={selected ? classes.highlight : null}
      onClick={() => history.push(path)}
    >
      {text}
    </ListItem>
  )
}
