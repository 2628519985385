import CircularProgress from "@material-ui/core/CircularProgress"
import {
  priceRange,
  sqfPriceRange,
  schoolColors,
  crimeColors,
} from "./LocationCard"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import React, { useState, useEffect } from "react"
import axios from "axios"
import PropTypes from "prop-types"
// Router
import { useHistory } from "react-router-dom"
// Material UI
import { makeStyles } from "@material-ui/core/styles"
import { DataGrid } from "@material-ui/data-grid"
import { PieChart } from "react-minimal-pie-chart"
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TextField,
  Button,
} from "@material-ui/core"
import {
  Train as TrainIcon,
  DirectionsBus as BusIcon,
  Info as InfoIcon,
} from "@material-ui/icons"

const API_KEY = "AIzaSyB6iQ4p_8PY5SstmNVIAqJY9EfpCMRr3UY"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper square style={{ marginBottom: "50px", borderTop: "none" }}>
          <Box px={3} py={4}>
            <Typography>{children}</Typography>
          </Box>
        </Paper>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))

function showNutshell(town) {
  return Boolean(
    town.whatToExpect &&
      town.whatNotToExpect &&
      town.lifestyle &&
      town.thingsYoullLove
  )
}

export default function SimpleTabs({ town, bedrooms }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  let tabIndex = 0
  let tabPanelIndex = 0

  return (
    <div className={classes.root}>
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
        >
          {showNutshell(town) && (
            <Tab label="In a Nutshell" {...a11yProps(tabIndex++)} />
          )}
          <Tab label="Map" {...a11yProps(tabIndex++)} />
          <Tab label="Transport Links" {...a11yProps(tabIndex++)} />
          <Tab label="Key Stats" {...a11yProps(tabIndex++)} />
          <Tab label="Local Amenities" {...a11yProps(tabIndex++)} />
          <Tab label="View Properties" {...a11yProps(tabIndex++)} />
          <Tab label="Feedback" {...a11yProps(tabIndex++)} />
        </Tabs>
      </Paper>

      {showNutshell(town) && (
        <TabPanel value={value} index={tabPanelIndex++}>
          <Nutshell town={town} />
        </TabPanel>
      )}
      <TabPanel value={value} index={tabPanelIndex++}>
        <Map
          town={town}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </TabPanel>
      <TabPanel value={value} index={tabPanelIndex++}>
        <Transport town={town} />
      </TabPanel>
      <TabPanel value={value} index={tabPanelIndex++}>
        <Stats town={town} bedrooms={bedrooms} />
      </TabPanel>
      <TabPanel value={value} index={tabPanelIndex++}>
        <Amenities town={town} />
      </TabPanel>
      <TabPanel value={value} index={tabPanelIndex++}>
        <Properties town={town} />
      </TabPanel>
      <TabPanel value={value} index={tabPanelIndex++}>
        <Feedback town={town} />
      </TabPanel>
    </div>
  )
}

function Nutshell({ town }) {
  return (
    <>
      <Box display="flex" mb={3}>
        <Box minWidth="50px">
          <Typography variant="h2">1.</Typography>
        </Box>
        <Box>
          <Typography variant="h5">
            <strong>What to expect</strong>
          </Typography>
          <Typography>{town.whatToExpect}</Typography>
        </Box>
      </Box>
      <Box display="flex" mb={3}>
        <Box minWidth="50px">
          <Typography variant="h2">2.</Typography>
        </Box>
        <Box>
          <Typography variant="h5">
            <strong>What not to expect</strong>
          </Typography>
          <Typography>{town.whatNotToExpect}</Typography>
        </Box>
      </Box>
      <Box display="flex" mb={3}>
        <Box minWidth="50px">
          <Typography variant="h2">3.</Typography>
        </Box>
        <Box>
          <Typography variant="h5">
            <strong>The lifestyle</strong>
          </Typography>
          <Typography>{town.lifestyle}</Typography>
        </Box>
      </Box>
      <Box display="flex" mb={2}>
        <Box minWidth="50px">
          <Typography variant="h2">4.</Typography>
        </Box>
        <Box>
          <Typography variant="h5">
            <strong>Things you'll love</strong>
          </Typography>
          <Typography>{town.thingsYoullLove}</Typography>
        </Box>
      </Box>
    </>
  )
}

const Map = withScriptjs(
  withGoogleMap(({ town }) => {
    const position = {
      lat: parseFloat(town.latitude),
      lng: parseFloat(town.longitude),
    }

    return (
      <Box border={1} borderColor="grey.300">
        <GoogleMap defaultZoom={12} defaultCenter={position}>
          <Marker position={position} />
        </GoogleMap>
      </Box>
    )
  })
)

function Transport({ town }) {
  const trainStations = JSON.parse(town.trainStations)
  const dlrStations = JSON.parse(town.dlrStations)
  const overgroundStations = JSON.parse(town.overgroundStations)
  const tubeStations = JSON.parse(town.tubeStations)
  return (
    <>
      {trainStations && (
        <Box mb={3}>
          <Typography variant="h5">
            <strong>Train Stations</strong>
          </Typography>
          <Table>
            <TableBody>
              {trainStations.map((station) => (
                <TransportRow name={station} type="train" />
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      {dlrStations && (
        <Box mb={3}>
          <Typography variant="h5">
            <strong>DLR Stations</strong>
          </Typography>
          <Table>
            <TableBody>
              {dlrStations.map((station) => (
                <TransportRow name={station} type="train" />
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      {overgroundStations && (
        <Box mb={3}>
          <Typography variant="h5">
            <strong>Overground Stations</strong>
          </Typography>
          <Table>
            <TableBody>
              {overgroundStations.map((station) => (
                <TransportRow name={station} type="train" />
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      {tubeStations && (
        <Box mb={3}>
          <Typography variant="h5">
            <strong>Tube Stations</strong>
          </Typography>
          <Table>
            <TableBody>
              {tubeStations.map((station) => (
                <TransportRow name={station} type="train" />
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  )
}

function TransportRow({ name, type }) {
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
    </TableRow>
  )
}

function Stats({ town, bedrooms }) {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h5">
          <strong>House Prices</strong>
        </Typography>
        <Table>
          <TableBody>
            <StatsPriceRow
              title={`Average price (${bedrooms} bed)`}
              data={priceRange(town, bedrooms, true)}
            />
            <StatsPriceRow
              title="Average price (all)"
              data={priceRange(town, 0, true)}
            />
            <StatsPriceRow title="Price per sqft" data={sqfPriceRange(town)} />
            <StatsPriceRow
              title={`Average rent (${bedrooms} bed)`}
              data={priceRange(town, bedrooms, false)}
            />
            <StatsPriceRow
              title="Average rent (all)"
              data={priceRange(town, 0, false)}
            />
          </TableBody>
        </Table>
      </Box>
      <Box mb={3}>
        <Typography variant="h5">
          <strong>Schools</strong>
        </Typography>
        <StatsRow
          title="Schools"
          data={
            town.averageSchoolRating && town.averageSchoolRating != "No schools"
              ? town.averageSchoolRating
              : "Coming soon"
          }
          color={schoolColors[town.averageSchoolRating]}
        />

        <SchoolsTable town={town} />
      </Box>
      <Box mb={3}>
        <Typography variant="h5">
          <strong>Crime</strong>
        </Typography>
        <StatsRow
          title="Crime"
          data={
            town.averageCrimeRating ? town.averageCrimeRating : "Coming soon"
          }
          color={crimeColors[town.averageCrimeRating]}
        />

        <CrimeGraph town={town} />
      </Box>
    </>
  )
}

const columns = [
  { field: "name", headerName: "Name", width: 300 },
  { field: "type", headerName: "Type", width: 150 },
  { field: "phase", headerName: "Phase", width: 100 },
  { field: "sixthForm", headerName: "Sixth form", width: 100 },
  { field: "inspectionPub", headerName: "Inspection Published", width: 175 },
  { field: "rating", headerName: "Rating", width: 150 },
  {
    field: "url",
    headerName: "URL",
    width: 150,
    renderCell: (data) => {
      return (
        <a href={data.value} target="_blank">
          View
        </a>
      )
    },
  },
]

export function SchoolsTable({ town }) {
  const pageSize = 10
  const [schools, setSchools] = useState([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState()

  useEffect(() => {
    setLoading(true)
    const params = {
      page: page + 1,
      pageSize,
      town: town.id,
    }
    axios.get("/locations/schools", { params }).then((response) => {
      setRowCount(response.data.count)
      setSchools(response.data.results)
      setLoading(false)
    })
  }, [page])

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            autoHeight
            rows={schools}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            className="clickable"
            density="compact"
            paginationMode="server"
            loading={loading}
            page={page}
            onPageChange={(params) => {
              setPage(params.page)
            }}
          />
        </div>
      </div>
    </div>
  )
}

function CrimeGraph({ town }) {
  const [crimes, setCrimes] = useState([])
  const [observations, setObservations] = useState([])
  const colors = [
    "#C0392B",
    "#9B59B6",
    "#2980B9",
    "#48C9B0",
    "#52BE80",
    "#F4D03F",
    "#F0B27A",
    "#BDC3C7",
  ]

  useEffect(() => {
    const params = {
      town: town.id,
    }
    axios.get("/locations/crimes", { params }).then((response) => {
      let build = []
      let i = 0
      response.data.results.map((crime) => {
        build.push({
          title: crime.name,
          value: crime.percentage,
          color: colors[i],
        })
        if (i == colors.length - 1) {
          i = 2
        } else {
          i++
        }
      })
      setCrimes(build)
    })
    axios.get("/locations/crime-observations", { params }).then((response) => {
      setObservations(response.data.results)
    })
  }, [])

  return (
    <PieChart
      lineWidth={30}
      data={crimes}
      label={({ dataEntry }) => {
        console.log({ dataEntry })
        return dataEntry.title + ": " + dataEntry.value
      }}
      labelPosition={65}
      labelStyle={(index) => ({
        fill: crimes[index].color,
        fontSize: "2px",
        fontFamily: "sans-serif",
      })}
    />
  )
}

function StatsPriceRow({ title, data }) {
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell style={{ width: "1px", textAlign: "right" }}>
        <Typography color="primary">{data}</Typography>
      </TableCell>
    </TableRow>
  )
}

function StatsRow({ title, data, color }) {
  return <Box color={color}>{data}</Box>
}

function Amenities({ town }) {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios.get(`/locations/towns/${town.id}/amenities/`).then((response) => {
      setData(response.data)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <Typography variant="h5">
        <strong>Number of Key Amenities</strong>
      </Typography>
      <Table>
        <TableBody>
          <AmenitiesRow
            title="Restaurants"
            loading={loading}
            data={data.restaurants}
          />
          <AmenitiesRow title="Cafes" loading={loading} data={data.cafes} />
          <AmenitiesRow
            title="Bars and pubs"
            loading={loading}
            data={data.bars}
          />
          <AmenitiesRow title="Parks" loading={loading} data={data.parks} />
          <AmenitiesRow
            title="Beauty and hair salons"
            loading={loading}
            data={data.salons}
          />
        </TableBody>
      </Table>
    </>
  )
}

function AmenitiesRow({ title, data, loading }) {
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell style={{ width: "1px", textAlign: "right" }}>
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <Typography>{data == 60 ? "60+" : data}</Typography>
        )}
      </TableCell>
    </TableRow>
  )
}

function Properties() {
  const history = useHistory()

  return (
    <>
      <Typography variant="h5" gutterBottom={true}>
        <strong>Coming Soon</strong>
      </Typography>
      <Typography paragraph>
        You'll be excited to know the whereshome team are currently working on
        bringing you the most relevant property listings in your selected area
        right here!
      </Typography>
      <Typography gutterBottom>
        For any estate agents interested in listing properties with Whereshome,
        please don’t hesitate to reach out via the button below– we’d love to
        hear from you too!
      </Typography>
      <Button
        style={{ marginTop: "16px" }}
        onClick={() => {
          history.push("/contact/")
          window.scrollTo(0, 0)
        }}
      >
        Contact Us
      </Button>
    </>
  )
}

function Feedback({ town }) {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [suggestions, setSuggestions] = useState("")

  async function submit() {
    const data = {
      fullName,
      email,
      suggestions,
      town: town.id,
    }
    await axios.post("/forms/feedback/", data)
    setFullName("")
    setEmail("")
    setSuggestions("")
  }

  return (
    <>
      <Typography variant="h5">
        <strong>How could we improve?</strong>
      </Typography>
      <Typography>
        We love hearing from our users. If you have any ideas of improvements we
        can make to this page, or to the site as a whole, please use the form
        below to let us know.
      </Typography>
      <Box display="flex" justifyContent="center" mt={4}>
        <Box width="100%" maxWidth="400px">
          <Box mb={2}>
            <TextField
              label="Full name"
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Email address"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Suggestions"
              multiline
              rows="4"
              value={suggestions}
              onChange={(event) => setSuggestions(event.target.value)}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Button onClick={submit}>Send Message</Button>
      </Box>
    </>
  )
}
