import moment from "moment"
import { useState, useEffect } from "react"

export function useTrigger() {
  const [value, setValue] = useState(0)
  return [value, () => setValue((value) => value + 1)]
}

export function useDebouncedState(initialState, delay) {
  const [state, setState] = useState(initialState)
  const [waiting, setWaiting] = useState(false)
  const [debouncedState, setDebouncedState] = useState(initialState)

  useEffect(() => {
    setWaiting(true)
    const handler = setTimeout(() => {
      setWaiting(false)
      setDebouncedState(state)
    }, delay)

    return () => clearTimeout(handler)
  }, [state])

  return [state, setState, debouncedState, waiting]
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return string
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function updateObjInList(items, index, property, value, setter) {
  const newItems = JSON.parse(JSON.stringify(items))
  newItems[index][property] = value
  setter(newItems)
}

export function getUrlParameter(
  name,
  string_to_search = window.location.search
) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
  var results = regex.exec(string_to_search)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "))
}

export function updateObjInListOfLists(
  items,
  firstIndex,
  secondIndex,
  firstProperty,
  secondProperty,
  value,
  setter
) {
  const newItems = JSON.parse(JSON.stringify(items))
  newItems[firstIndex][firstProperty][secondIndex][secondProperty] = value
  setter(newItems)
}

export function deleteObjInListByIndex(items, index, setters) {
  const newItems = JSON.parse(JSON.stringify(items))
  newItems.splice(index, 1)
  for (let setter of setters) {
    setter(newItems)
  }
}

export function deleteObjInListOfListsByIndex(
  items,
  firstIndex,
  secondIndex,
  property,
  setters
) {
  const newItems = JSON.parse(JSON.stringify(items))
  newItems[firstIndex][property].splice(secondIndex, 1)
  for (let setter of setters) {
    setter(newItems)
  }
}

export function deleteObjInListByPropValue(items, property, value, setters) {
  const newItems = items.filter(function (obj) {
    return obj[property] !== value
  })
  for (let setter of setters) {
    setter(newItems)
  }
}

export function debounce(fn, delay) {
  var timer = null
  return function () {
    var context = this,
      args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

export function expiredVaccine(nextVaccineDue) {
  if (moment(nextVaccineDue) < moment()) {
    return true
  } else {
    return false
  }
}

export function pushIfNotExist(newItem, array) {
  if (array.indexOf(newItem) === -1) {
    array.push(newItem)
  }
  return array
}

export function removeFromArrayByValue(value, array) {
  var index = array.indexOf(value)
  if (index !== -1) {
    array.splice(index, 1)
  }
  return array
}
