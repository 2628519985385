import React, { useState, useEffect } from "react"
import { v4 as uuid } from "uuid"
import { format } from "date-fns"
import axios from "axios"
// Router
import { useHistory } from "react-router-dom"
// Material UI
import { Paper, Box, Typography, TextField } from "@material-ui/core"
import { DataGrid } from "@material-ui/data-grid"
import { useDebouncedState, useTrigger } from "utilities"

export default function Locations() {
  const [searchTerm, setSearchTerm, debouncedSearchTerm] = useDebouncedState(
    "",
    300
  )

  return (
    <>
      <Typography variant="h2" gutterBottom={true}>
        Locations
      </Typography>
      <Paper>
        <Box p={2}>
          <Box maxWidth="300px" mt={1} mb={3}>
            <TextField
              label="Search locations..."
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </Box>
          <Table searchTerm={debouncedSearchTerm} />
        </Box>
      </Paper>
    </>
  )
}

const columns = [
  { field: "name", headerName: "Name", width: 300 },
  { field: "county", headerName: "County", width: 200 },
  { field: "postcodeArea", headerName: "Postcode Area", width: 150 },
  {
    field: "updatedAt",
    headerName: "Admin Update",
    width: 150,
    renderCell: (data) => {
      return format(new Date(data.value), "dd/MM/yyyy HH:mm")
    },
  },
  {
    field: "lastScraped",
    headerName: "Last Scraped",
    width: 150,
    valueGetter: (params) => {
      return format(new Date(params.row.updatedAt), "dd/MM/yyyy HH:mm")
    },
  },
]

export function Table({ searchTerm }) {
  const history = useHistory()
  const pageSize = 10
  const [locations, setLocations] = useState([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState()
  const [trigger, retrigger] = useTrigger()

  useEffect(() => {
    setLoading(true)
    const params = {
      page: page + 1,
      pageSize,
      search: searchTerm,
    }
    axios.get("/locations/towns", { params }).then((response) => {
      setRowCount(response.data.count)
      setLocations(response.data.results)
      setLoading(false)
    })
  }, [page, trigger])

  useEffect(() => {
    setPage(0)
    retrigger()
  }, [searchTerm])

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            autoHeight
            rows={locations}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            onRowClick={(row) => {
              history.push(`/admin/location/${row.id}`)
            }}
            className="clickable"
            density="compact"
            paginationMode="server"
            loading={loading}
            page={page}
            onPageChange={(params) => {
              setPage(params.page)
            }}
          />
        </div>
      </div>
    </div>
  )
}
