import { CameraAlt as CameraIcon } from "@material-ui/icons"
import { useState, useEffect } from "react"
import parse from "html-react-parser"
import axios from "axios"
import { useLocation, useHistory, useParams } from "react-router-dom"
// Router
// Material UI
import { Box, Breadcrumbs, Typography, Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// Components
import { ShareLocation, LocationTabs, RelatedPosts } from "Components/User"
import { getUrlParameter } from "utilities"

// styles
const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up("md")]: {
      position: "relative"
    }
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "325px",
    [theme.breakpoints.up("md")]: {
      width: "85%",
      height: "400px"
    }
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2)
  },
  descriptionBox: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFF",
    marginTop: "-4px",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "350px",
      right: "0",
      top: "250px",
      marginTop: "0px"
    }
  }
}))

export default function Location() {
  const { id } = useParams()
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [town, setTown] = useState({})
  const [showBreadcrumb, setShowBreadcrumb] = useState(false)
  const [bedrooms, setBedrooms] = useState()

  useEffect(() => {
    var newBedrooms = getUrlParameter("bedrooms")
    if (["1", "2", "3", "4", "5"].includes(newBedrooms) == false) {
      setBedrooms(2)
      history.replace(location.pathname + "?bedrooms=2")
    } else {
      setBedrooms(newBedrooms)
    }
  }, [])

  useEffect(() => {
    if (
      location.state &&
      location.state.from &&
      location.state.from.pathname == "/search"
    ) {
      setShowBreadcrumb(true)
    }

    axios.get(`/locations/towns/${id}/`).then((response) => {
      setTown(response.data)
      setLoading(false)
    })
  }, [])

  if (loading) return "loading..."

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        {showBreadcrumb && <Link onClick={history.goBack}>Search Results</Link>}
        <Typography color="textPrimary">{town.name}</Typography>
      </Breadcrumbs>
      <div className={classes.wrapper}>
        {town.image ? (
          <img src={town.image.url} alt={town.name} className={classes.img} />
        ) : (
          <img
            src="https://i.stack.imgur.com/y9DpT.jpg"
            alt={town.name}
            className={classes.img}
          />
        )}
        <Box p={2.5} className={classes.descriptionBox}>
          <Typography variant="h2">{town.name}</Typography>
          <Typography>{town.description}</Typography>
        </Box>
      </div>
      {town.image &&
        typeof town.image.attribution == "string" &&
        JSON.parse(
          town.image.attribution
            .replace(/"/g, "`")
            .replace(/'/g, '"')
            .replace(/`/g, "'")
        ).map((link) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CameraIcon
              fontSize="small"
              style={{ marginRight: "4px", color: "#b0b0b0" }}
            />
            <div className="attribution-link">{parse(link)}</div>
          </div>
        ))}
      <ShareLocation />
      <LocationTabs town={town} bedrooms={bedrooms} />
      <RelatedPosts town={town} />
    </>
  )
}
