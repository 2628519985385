import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
// Material UI
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// Images
import oxford from "../../oxford.jpg"

// styles
const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    height: "auto",
  },
}))

export default function Locations() {
  const classes = useStyles()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState()
  const [favorite, setFavorite] = useState()
  const [description, setDescription] = useState()
  const [whatToExpect, setWhatToExpect] = useState()
  const [whatNotToExpect, setWhatNotToExpect] = useState()
  const [lifestyle, setLifestyle] = useState()
  const [thingsYoullLove, setThingsYoullLove] = useState()
  const [image, setImage] = useState()

  async function saveChanges() {
    const data = {
      favorite,
      description,
      whatToExpect,
      whatNotToExpect,
      lifestyle,
      thingsYoullLove,
    }

    // upload image
    if (image.startsWith("blob:")) {
      let formData = new FormData()
      let blob = await fetch(image).then((r) => r.blob())
      formData.append("url", blob, "image." + blob.type.split("/")[1])
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }

      const response = await axios.post("/locations/images/", formData, options)
      data.image = response.data.id
    }

    axios.patch(`/locations/towns/${id}/`, data)
  }

  useEffect(() => {
    axios.get(`/locations/towns/${id}/`).then((response) => {
      const { data } = response
      if (data.image) setImage(data.image.url)
      setName(data.name)
      setFavorite(data.favorite)
      setDescription(data.description)
      setWhatToExpect(data.whatToExpect)
      setWhatNotToExpect(data.whatNotToExpect)
      setLifestyle(data.lifestyle)
      setThingsYoullLove(data.thingsYoullLove)
      setLoading(false)
    })
  }, [])

  if (loading) return "loading..."

  return (
    <>
      <Typography variant="h2" paragraph>
        Location: {name}
      </Typography>
      <Box maxWidth="700px" mb={3}>
        <Paper>
          <Box p={2}>
            <Typography variant="h4" paragraph>
              Basic Details
            </Typography>
            <Box mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checked"
                    color="primary"
                    checked={favorite}
                    onChange={(event) => setFavorite(event.target.checked)}
                  />
                }
                label="Include in favourite locations?"
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Description"
                multiline
                rows={5}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="What to expect"
                multiline
                rows={3}
                value={whatToExpect}
                onChange={(event) => setWhatToExpect(event.target.value)}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="What not to expect"
                multiline
                rows={3}
                value={whatNotToExpect}
                onChange={(event) => setWhatNotToExpect(event.target.value)}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="The lifestyle"
                multiline
                rows={3}
                value={lifestyle}
                onChange={(event) => setLifestyle(event.target.value)}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Things you'll love"
                multiline
                rows={3}
                value={thingsYoullLove}
                onChange={(event) => setThingsYoullLove(event.target.value)}
              />
            </Box>
            <Box maxWidth="300px">
              <img src={image} alt="" className={classes.img} />
              <Box display="flex" mt={1} className="mx-auto">
                <input
                  type="file"
                  name="file"
                  onChange={(event) => {
                    const file = event.target.files[0]
                    const url = URL.createObjectURL(file)
                    setImage(url)
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mb={5}>
        <Button onClick={saveChanges}>Save Changes</Button>
      </Box>
    </>
  )
}
