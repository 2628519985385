import React from "react"
import { AsyncAutoComplete } from "Components/Generic"
import queryString from "query-string"
// Router
import { useHistory } from "react-router-dom"
// Material UI
import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  ButtonGroup,
  Hidden,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import Autocomplete from "@material-ui/lab/Autocomplete"
// Images
import homebanner from "../../homebanner-2.jpg"
import { REGIONS, COUNTIES } from "../../constants"

// styles
const useStyles = makeStyles((theme) => ({
  banner: {
    background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${homebanner})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      backgroundPositionY: "-180px",
      backgroundPositionX: "right",
    },
  },
  title: {
    fontSize: "3rem",
    color: "#fff",
    textShadow: "0px 1px 4px rgba(0,0,0,0.7)",
    [theme.breakpoints.up("xs")]: {
      marginTop: "250px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "310px",
    },
  },
  formWrapper: {
    maxWidth: "1000px",
    width: "100%",
    background: "#fff",
    boxShadow: "rgb(17 51 83 / 10%) 0px 4px 12px 0px",
  },
  button: {
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
    },
  },
  typeButton: {
    borderRadius: "0px",
    "&.Mui-disabled": {
      backgroundColor: "#fff",
      background: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
}))

export default function HomeSearch() {
  const history = useHistory()
  const classes = useStyles()

  const [searchBy, setSearchBy] = React.useState("county")
  const [searchType, setSearchType] = React.useState("buy")
  const [maxPrice, setMaxPrice] = React.useState()
  const [bedrooms, setBedrooms] = React.useState()
  const [county, setCounty] = React.useState(null)
  const [region, setRegion] = React.useState(null)
  const [location, setLocation] = React.useState()
  const [radius, setRadius] = React.useState()

  function generateQueryString() {
    const params = {
      searchType,
      maxPrice,
      bedrooms,
      searchBy,
    }
    if (searchBy == "county") params["county"] = county
    if (searchBy == "region") params["region"] = region
    if (searchBy == "location") {
      params["location"] = location.id
      params["locationName"] = location.name
      params["radius"] = radius
    }
    return queryString.stringify(params)
  }

  const handleChange = (event) => {
    setSearchBy(event.target.value)
  }

  return (
    <Box className={classes.banner}>
      <Box display="flex" mb={2}>
        <Box maxWidth="850px" className="mx-auto">
          <Typography variant="h1" align="center" className={classes.title}>
            Find your dream location, the key to your dream home
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box maxWidth="1000px" width="100%">
          <ButtonGroup size="large">
            <Button
              className={classes.typeButton}
              color={searchType == "buy" ? "default" : "primary"}
              onClick={() => setSearchType("buy")}
              disabled={searchType == "buy"}
            >
              Buy
            </Button>
            <Button
              className={classes.typeButton}
              color={searchType == "rent" ? "default" : "primary"}
              onClick={() => setSearchType("rent")}
              disabled={searchType == "rent"}
            >
              Rent
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mb={4}>
        <Box display="flex" className={classes.formWrapper}>
          <Box p={1} flexGrow={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  variant="standard"
                  label="Search by"
                  select
                  onChange={handleChange}
                  value={searchBy}
                >
                  <MenuItem value="county">County</MenuItem>
                  <MenuItem value="region">Region</MenuItem>
                  <MenuItem value="location">Location & Radius</MenuItem>
                </TextField>
              </Grid>
              {searchBy === "county" ? (
                <Grid item xs={12} sm={6} md={5}>
                  <Autocomplete
                    value={county}
                    onChange={(event, value) => setCounty(value)}
                    options={COUNTIES}
                    getOptionLabel={(county) => county}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="County"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              ) : null}
              {searchBy === "region" ? (
                <Grid item xs={12} sm={6} md={5}>
                  <Autocomplete
                    value={region}
                    onChange={(event, value) => setRegion(value)}
                    options={REGIONS}
                    getOptionLabel={(region) => region}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Region"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              ) : null}
              {searchBy === "location" ? (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <AsyncAutoComplete
                      variant="standard"
                      value={location}
                      url="/locations/towns/"
                      label="Search location..."
                      onChange={(value) => {
                        setLocation(value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      variant="standard"
                      select
                      label="Radius"
                      value={radius}
                      onChange={(event) => setRadius(event.target.value)}
                    >
                      <MenuItem value="5">5 miles</MenuItem>
                      <MenuItem value="10">10 miles</MenuItem>
                      <MenuItem value="25">25 miles</MenuItem>
                      <MenuItem value="50">50 miles</MenuItem>
                      <MenuItem value="100">100 miles</MenuItem>
                      <MenuItem value="250">250 miles</MenuItem>
                    </TextField>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sm={6} md={2}>
                {searchType == "buy" ? (
                  <TextField
                    variant="standard"
                    label="Max average price"
                    select
                    value={maxPrice}
                    onChange={(event) => setMaxPrice(event.target.value)}
                  >
                    <MenuItem value="">
                      <i>Any</i>
                    </MenuItem>
                    <MenuItem value="100000">£100,000</MenuItem>
                    <MenuItem value="150000">£150,000</MenuItem>
                    <MenuItem value="200000">£200,000</MenuItem>
                    <MenuItem value="250000">£250,000</MenuItem>
                    <MenuItem value="300000">£300,000</MenuItem>
                    <MenuItem value="350000">£350,000</MenuItem>
                    <MenuItem value="400000">£400,000</MenuItem>
                    <MenuItem value="450000">£450,000</MenuItem>
                    <MenuItem value="500000">£500,000</MenuItem>
                    <MenuItem value="550000">£550,000</MenuItem>
                    <MenuItem value="600000">£600,000</MenuItem>
                    <MenuItem value="650000">£650,000</MenuItem>
                    <MenuItem value="700000">£700,000</MenuItem>
                    <MenuItem value="750000">£750,000</MenuItem>
                    <MenuItem value="800000">£800,000</MenuItem>
                    <MenuItem value="850000">£850,000</MenuItem>
                    <MenuItem value="900000">£900,000</MenuItem>
                    <MenuItem value="950000">£950,000</MenuItem>
                    <MenuItem value="1000000">£1,000,000</MenuItem>
                    <MenuItem value="1250000">£1,250,000</MenuItem>
                    <MenuItem value="1500000">£1,500,000</MenuItem>
                    <MenuItem value="1750000">£1,750,000</MenuItem>
                    <MenuItem value="2000000">£2,000,000</MenuItem>
                    <MenuItem value="2500000">£2,500,000</MenuItem>
                    <MenuItem value="3000000">£3,000,000</MenuItem>
                    <MenuItem value="3500000">£3,500,000</MenuItem>
                    <MenuItem value="4000000">£4,000,000</MenuItem>
                    <MenuItem value="4500000">£4,500,000</MenuItem>
                    <MenuItem value="5000000">£5,000,000</MenuItem>
                    <MenuItem value="7500000">£7,500,000</MenuItem>
                    <MenuItem value="10000000">£10,000,000</MenuItem>
                    <MenuItem value="15000000">£15,000,000</MenuItem>
                    <MenuItem value="20000000">£20,000,000</MenuItem>
                    <MenuItem value="25000000">£25,000,000</MenuItem>
                    <MenuItem value="30000000">£30,000,000</MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    select
                    label="Max Price"
                    value={maxPrice}
                    variant="standard"
                    onChange={(event) => setMaxPrice(event.target.value)}
                  >
                    <MenuItem value="">
                      <i>Any</i>
                    </MenuItem>
                    <MenuItem value="100">£100</MenuItem>
                    <MenuItem value="200">£200</MenuItem>
                    <MenuItem value="300">£300</MenuItem>
                    <MenuItem value="400">£400</MenuItem>
                    <MenuItem value="500">£500</MenuItem>
                    <MenuItem value="600">£600</MenuItem>
                    <MenuItem value="700">£700</MenuItem>
                    <MenuItem value="800">£800</MenuItem>
                    <MenuItem value="900">£900</MenuItem>
                    <MenuItem value="1000">£1,000</MenuItem>
                    <MenuItem value="1250">£1,250</MenuItem>
                    <MenuItem value="1500">£1,500</MenuItem>
                    <MenuItem value="1750">£1,750</MenuItem>
                    <MenuItem value="2000">£2,000</MenuItem>
                    <MenuItem value="2500">£2,500</MenuItem>
                    <MenuItem value="3000">£3,000</MenuItem>
                    <MenuItem value="5000">£5,000</MenuItem>
                    <MenuItem value="10000">£10,000</MenuItem>
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  variant="standard"
                  label="Bedrooms"
                  select
                  value={bedrooms}
                  onChange={(event) => setBedrooms(event.target.value)}
                >
                  <MenuItem value="">
                    <i>Any</i>
                  </MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                </TextField>
              </Grid>
              <Hidden mdUp>
                <Grid item xs={12}>
                  <Box display="flex">
                    <Button
                      className={classes.button}
                      startIcon={<SearchIcon />}
                      size="large"
                      onClick={() =>
                        history.push("/search/?" + generateQueryString())
                      }
                    >
                      Search
                    </Button>
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
          <Hidden smDown>
            <Button
              className={classes.button}
              onClick={() => history.push("/search/?" + generateQueryString())}
            >
              <SearchIcon />
            </Button>
          </Hidden>
        </Box>
      </Box>
    </Box>
  )
}
