import { BLOG_CATEGORIES } from "../../constants"
import { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { format } from "date-fns"
import axios from "axios"
// Router
import { useParams } from "react-router-dom"
// Material UI
import { Box, Typography, Breadcrumbs } from "@material-ui/core"
import parse from "html-react-parser"

export default function Post() {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState({})

  useEffect(() => {
    const request = isNaN(parseInt(id))
      ? axios
          .get(`/blog/posts/?url=${id}`)
          .then((response) => response.data.results[0])
      : axios.get(`/blog/posts/${id}/`).then((response) => response.data)

    request.then((data) => {
      setPost(data)
      setLoading(false)
    })
  }, [id])

  if (loading) return "loading..."

  return (
    <Box pt={2} pb={8}>
      <Typography variant="h2" gutterBottom={true}>
        {post.title}
      </Typography>
      <Box mb={3}>
        <Breadcrumbs>
          <Typography color="textSecondary">
            {format(new Date(post.date), "dd MMM yyyy")}
          </Typography>
          <Typography color="textSecondary">
            {BLOG_CATEGORIES[post.category]}
          </Typography>
        </Breadcrumbs>
      </Box>
      <div className="blog-content">{parse(post.content)}</div>
      <MetaTags>
        <title>{post.seoTitle}</title>
        <meta name="description" content={post.seoDescription} />
      </MetaTags>
    </Box>
  )
}
