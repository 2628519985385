import { formatPrice, formatPriceK } from "./LocationCard"
// Material UI
import { Box, Typography, Button } from "@material-ui/core"
// Components
import { RefineSearch } from "Components/User"
import { SCHOOL_RATINGS, CRIME_RATINGS } from "../../constants"

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function ActiveFilters({ searchOptions, search, buying }) {
  return (
    <Box
      px={2}
      py={1}
      bgcolor="grey.200"
      display="flex"
      alignItems="center"
      flexWrap="wrap"
    >
      {Boolean(searchOptions[searchOptions.searchBy]) && (
        <>
          <SingleFilter
            label="Search by:"
            filter={capitalize(searchOptions.searchBy)}
          />
          {searchOptions.searchBy == "county" && (
            <SingleFilter label="County:" filter={searchOptions.county} />
          )}
          {searchOptions.searchBy == "region" && (
            <SingleFilter label="Region:" filter={searchOptions.region} />
          )}
          {searchOptions.searchBy == "location" && (
            <>
              <SingleFilter
                label="Location:"
                filter={searchOptions.location.name}
              />
              <SingleFilter
                label="Radius:"
                filter={searchOptions.radius + " miles"}
              />
            </>
          )}
        </>
      )}
      <SingleFilter
        label="Looking to:"
        filter={capitalize(searchOptions.searchType)}
      />
      {Boolean(searchOptions.maxPrice) && (
        <SingleFilter
          label="Max average price:"
          filter={
            buying
              ? formatPriceK(searchOptions.maxPrice)
              : formatPrice(searchOptions.maxPrice)
          }
        />
      )}
      {Boolean(searchOptions.maxPricePerSqft) && (
        <SingleFilter
          label="Max average price / sqft:"
          filter={formatPrice(searchOptions.maxPricePerSqft)}
        />
      )}
      {Boolean(searchOptions.bedrooms) && (
        <SingleFilter label="Bedrooms:" filter={searchOptions.bedrooms} />
      )}
      {Boolean(searchOptions.minSchoolRating) && (
        <SingleFilter
          label="Min School Rating:"
          filter={SCHOOL_RATINGS[searchOptions.minSchoolRating]}
        />
      )}
      {Boolean(searchOptions.maxCrimeLevel) && (
        <SingleFilter
          label="Max Crime Level:"
          filter={CRIME_RATINGS[searchOptions.maxCrimeLevel]}
        />
      )}
      {Boolean(searchOptions.areaType) && (
        <SingleFilter label="Area Type:" filter={searchOptions.areaType} />
      )}
      {Boolean(searchOptions.amenities) && (
        <SingleFilter
          label="Local Amenites:"
          filter={searchOptions.amenities}
        />
      )}
      <RefineSearch
        search={search}
        searchOptions={searchOptions}
        buying={buying}
      />
    </Box>
  )
}

function SingleFilter({ label, filter }) {
  return (
    <Box mr={4} mt={1} mb={1}>
      <Typography variant="body2">{label}</Typography>
      <Typography>
        <strong>{filter}</strong>
      </Typography>
    </Box>
  )
}
