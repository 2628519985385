import { Box, CircularProgress } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"

export default function Loading() {
  const theme = useTheme()

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <CircularProgress
        color="secondary"
        style={{ padding: theme.spacing(2) }}
      />
    </Box>
  )
}
