import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { AsyncAutoComplete } from "Components/Generic"
// Material UI
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  TextField,
  MenuItem
} from "@material-ui/core"
import { COUNTIES, REGIONS } from "../../constants"

function clean(obj) {
  for (var propName in obj) {
    if (Boolean(obj[propName] == false)) {
      delete obj[propName]
    }
  }
  return obj
}

export default function RefineSearch({ search, searchOptions, buying }) {
  const [open, setOpen] = React.useState(false)
  const [searchBy, setSearchBy] = React.useState(searchOptions.searchBy)
  const [county, setCounty] = React.useState(searchOptions.county || null)
  const [region, setRegion] = React.useState(searchOptions.region || null)
  const [location, setLocation] = React.useState(searchOptions.location)
  const [radius, setRadius] = React.useState(searchOptions.radius)
  const [maxPrice, setMaxPrice] = React.useState(searchOptions.maxPrice)
  const [maxPricePerSqft, setMaxPricePerSqft] = React.useState(
    searchOptions.maxPricePerSqft
  )
  const [bedrooms, setBedrooms] = React.useState(searchOptions.bedrooms)
  const [minSchoolRating, setMinSchoolRating] = React.useState(
    searchOptions.minSchoolRating
  )
  const [maxCrimeLevel, setMaxCrimeLevel] = React.useState(
    searchOptions.maxCrimeLevel
  )
  const [areaType, setAreaType] = React.useState(searchOptions.areaType)
  const [amenities, setAmenities] = React.useState(searchOptions.amenities)

  function handleClickOpen() {
    setOpen(true)
  }
  function handleClose() {
    setOpen(false)
  }

  function handleSearch() {
    const options = {
      searchBy,
      maxPrice,
      maxPricePerSqft,
      bedrooms,
      minSchoolRating,
      maxCrimeLevel,
      areaType,
      amenities
    }
    if (searchBy == "county") options["county"] = county
    if (searchBy == "region") options["region"] = region
    if (searchBy == "location") {
      options["location"] = location
      options["radius"] = radius
    }
    search(clean(options))
    handleClose()
  }

  return (
    <>
      <Button className="ml-auto" onClick={handleClickOpen}>
        Refine Search
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <strong>Refine Search</strong>
        </DialogTitle>
        <DialogContent>
          <Typography>Search by:</Typography>
          <RadioGroup
            aria-label="searchBy"
            name="searchBY"
            value={searchBy}
            onChange={(event) => setSearchBy(event.target.value)}
            style={{ flexDirection: "row", marginBottom: "8px" }}
          >
            <FormControlLabel
              value="county"
              control={<Radio />}
              label="County"
            />
            <FormControlLabel
              value="region"
              control={<Radio />}
              label="Region"
            />
            <FormControlLabel
              value="location"
              control={<Radio />}
              label="Location"
            />
          </RadioGroup>
          <Grid container spacing={2}>
            {searchBy === "county" ? (
              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={county}
                  onChange={(event, value) => setCounty(value)}
                  options={COUNTIES}
                  getOptionLabel={(county) => county}
                  renderInput={(params) => (
                    <TextField {...params} label="County" variant="outlined" />
                  )}
                />
              </Grid>
            ) : null}
            {searchBy === "region" ? (
              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={region}
                  onChange={(event, value) => setRegion(value)}
                  options={REGIONS}
                  getOptionLabel={(region) => region}
                  renderInput={(params) => (
                    <TextField {...params} label="Region" variant="outlined" />
                  )}
                />
              </Grid>
            ) : null}
            {searchBy === "location" ? (
              <>
                <Grid item xs={12} md={6}>
                  <AsyncAutoComplete
                    value={location}
                    url="/locations/towns/"
                    label="Search location..."
                    onChange={(value) => {
                      setLocation(value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    label="Radius"
                    value={radius}
                    onChange={(event) => setRadius(event.target.value)}
                  >
                    <MenuItem value="5">5 miles</MenuItem>
                    <MenuItem value="10">10 miles</MenuItem>
                    <MenuItem value="25">25 miles</MenuItem>
                    <MenuItem value="50">50 miles</MenuItem>
                    <MenuItem value="100">100 miles</MenuItem>
                    <MenuItem value="250">250 miles</MenuItem>
                  </TextField>
                </Grid>
              </>
            ) : null}
          </Grid>
          <Box mt={3}>
            <Typography variant="h5" paragraph={true}>
              Property Filters
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {buying ? (
                  <TextField
                    select
                    label="Max Price"
                    value={maxPrice}
                    onChange={(event) => setMaxPrice(event.target.value)}
                  >
                    <MenuItem value="">
                      <i>Any</i>
                    </MenuItem>
                    <MenuItem value="100000">£100,000</MenuItem>
                    <MenuItem value="150000">£150,000</MenuItem>
                    <MenuItem value="200000">£200,000</MenuItem>
                    <MenuItem value="250000">£250,000</MenuItem>
                    <MenuItem value="300000">£300,000</MenuItem>
                    <MenuItem value="350000">£350,000</MenuItem>
                    <MenuItem value="400000">£400,000</MenuItem>
                    <MenuItem value="450000">£450,000</MenuItem>
                    <MenuItem value="500000">£500,000</MenuItem>
                    <MenuItem value="550000">£550,000</MenuItem>
                    <MenuItem value="600000">£600,000</MenuItem>
                    <MenuItem value="650000">£650,000</MenuItem>
                    <MenuItem value="700000">£700,000</MenuItem>
                    <MenuItem value="750000">£750,000</MenuItem>
                    <MenuItem value="800000">£800,000</MenuItem>
                    <MenuItem value="850000">£850,000</MenuItem>
                    <MenuItem value="900000">£900,000</MenuItem>
                    <MenuItem value="950000">£950,000</MenuItem>
                    <MenuItem value="1000000">£1,000,000</MenuItem>
                    <MenuItem value="1250000">£1,250,000</MenuItem>
                    <MenuItem value="1500000">£1,500,000</MenuItem>
                    <MenuItem value="1750000">£1,750,000</MenuItem>
                    <MenuItem value="2000000">£2,000,000</MenuItem>
                    <MenuItem value="2500000">£2,500,000</MenuItem>
                    <MenuItem value="3000000">£3,000,000</MenuItem>
                    <MenuItem value="3500000">£3,500,000</MenuItem>
                    <MenuItem value="4000000">£4,000,000</MenuItem>
                    <MenuItem value="4500000">£4,500,000</MenuItem>
                    <MenuItem value="5000000">£5,000,000</MenuItem>
                    <MenuItem value="7500000">£7,500,000</MenuItem>
                    <MenuItem value="10000000">£10,000,000</MenuItem>
                    <MenuItem value="15000000">£15,000,000</MenuItem>
                    <MenuItem value="20000000">£20,000,000</MenuItem>
                    <MenuItem value="25000000">£25,000,000</MenuItem>
                    <MenuItem value="30000000">£30,000,000</MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    select
                    label="Max Price"
                    value={maxPrice}
                    onChange={(event) => setMaxPrice(event.target.value)}
                  >
                    <MenuItem value="">
                      <i>Any</i>
                    </MenuItem>
                    <MenuItem value="100">£100</MenuItem>
                    <MenuItem value="200">£200</MenuItem>
                    <MenuItem value="300">£300</MenuItem>
                    <MenuItem value="400">£400</MenuItem>
                    <MenuItem value="500">£500</MenuItem>
                    <MenuItem value="600">£600</MenuItem>
                    <MenuItem value="700">£700</MenuItem>
                    <MenuItem value="800">£800</MenuItem>
                    <MenuItem value="900">£900</MenuItem>
                    <MenuItem value="1000">£1,000</MenuItem>
                    <MenuItem value="1250">£1,250</MenuItem>
                    <MenuItem value="1500">£1,500</MenuItem>
                    <MenuItem value="1750">£1,750</MenuItem>
                    <MenuItem value="2000">£2,000</MenuItem>
                    <MenuItem value="2500">£2,500</MenuItem>
                    <MenuItem value="3000">£3,000</MenuItem>
                    <MenuItem value="5000">£5,000</MenuItem>
                    <MenuItem value="10000">£10,000</MenuItem>
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Bedrooms"
                  value={bedrooms}
                  onChange={(event) => setBedrooms(event.target.value)}
                >
                  <MenuItem value="">
                    <i>Any</i>
                  </MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Max price / sqft"
                  value={maxPricePerSqft}
                  onChange={(event) => setMaxPricePerSqft(event.target.value)}
                >
                  <MenuItem value="">
                    <i>Any</i>
                  </MenuItem>
                  <MenuItem value="100">£100</MenuItem>
                  <MenuItem value="200">£200</MenuItem>
                  <MenuItem value="300">£300</MenuItem>
                  <MenuItem value="400">£400</MenuItem>
                  <MenuItem value="500">£500</MenuItem>
                  <MenuItem value="600">£600</MenuItem>
                  <MenuItem value="700">£700</MenuItem>
                  <MenuItem value="800">£800</MenuItem>
                  <MenuItem value="900">£900</MenuItem>
                  <MenuItem value="1000">£1,000</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} mb={2}>
            <Typography variant="h5" paragraph={true}>
              Area Filters
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Min school rating"
                  value={minSchoolRating}
                  onChange={(event) => setMinSchoolRating(event.target.value)}
                >
                  <MenuItem value="">
                    <i>Any</i>
                  </MenuItem>
                  <MenuItem value="4">Very Good</MenuItem>
                  <MenuItem value="3">Good</MenuItem>
                  <MenuItem value="2">Average</MenuItem>
                  <MenuItem value="1">Poor</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Max crime level"
                  value={maxCrimeLevel}
                  onChange={(event) => setMaxCrimeLevel(event.target.value)}
                >
                  <MenuItem value="">
                    <i>Any</i>
                  </MenuItem>
                  <MenuItem value="1">Very Low</MenuItem>
                  <MenuItem value="2">Low</MenuItem>
                  <MenuItem value="3">Average</MenuItem>
                  <MenuItem value="4">High</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Area type"
                  value={areaType}
                  onChange={(event) => setAreaType(event.target.value)}
                >
                  <MenuItem value="">
                    <i>Any</i>
                  </MenuItem>
                  <MenuItem value="City">City</MenuItem>
                  <MenuItem value="Town">Town</MenuItem>
                  <MenuItem value="Village">Village</MenuItem>
                  <MenuItem value="Suburban Area">Suburban Area</MenuItem>
                  <MenuItem value="Hamlet">Hamlet</MenuItem>
                  <MenuItem value="Island">Island</MenuItem>
                  <MenuItem value="Other Settlement">Other Settlement</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Local amenities"
                  value={amenities}
                  onChange={(event) => setAmenities(event.target.value)}
                >
                  <MenuItem value="">
                    <i>Any</i>
                  </MenuItem>
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSearch} fullWidth={true}>
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
