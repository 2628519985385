import { useState, createContext } from "react"

const Context = createContext()

function Provider({ children }) {
  const [requesting, setRequesting] = useState(0)

  return (
    <Context.Provider value={{ requesting, setRequesting }}>
      {children}
    </Context.Provider>
  )
}

const RequestingContext = { Context, Provider }
export default RequestingContext
