// Router
import { useState } from "react"
import { useHistory } from "react-router-dom"
// Material UI
import { Grid, Box, Paper, Button, Typography, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// Images
import tool from "../../cosy-living-room.jpg"
import partnerLiquid from "../../partner-liquid.png"
import partnerPd from "../../partner-pd.png"
import partnerDasa from "../../partner-dasa.jpg"
import partnerMc from "../../partner-mc.jpg"
import partnerPet from "../../partner-pet.jpg"
import { useEffect } from "react"

// styles
const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: "cover",
    width: "100%",
    height: "350px",
  },
  logoBox: {
    boxSizing: "border-box",
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  logoBoxPush: {
    boxSizing: "border-box",
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginLeft: "25%",
    },
  },
  partnerLogo: {
    objectFit: "cover",
    width: "100%",
    height: "auto",
  },
}))

export default function Services() {
  const history = useHistory()
  const classes = useStyles()
  const css = `
  .services-page li {
    margin-top: 20px;
  }`
  const [mainParaHeight, setMainParaHeight] = useState("200px")
  const [otherHeight, setOtherHeight] = useState("40px")

  useEffect(() => {
    if (window.innerWidth > 960) {
      setMainParaHeight("200px")
      setOtherHeight("40px")
    } else {
      setMainParaHeight("auto")
      setOtherHeight("auto")
    }
  }, [window.innerWidth])

  return (
    <div class="services-page">
      <Box py={2}>
        <style>{css}</style>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1" align="center" gutterBottom={true}>
              What We Offer
            </Typography>
            <Box display="flex">
              <Box maxWidth="800px" className="mx-auto">
                <Typography color="textSecondary" align="center" variant="h5">
                  Once we help you find a place that feels like home via our
                  seamless search tool, we’ll connect you with local services to
                  take care of the next steps, or help you ourselves with our
                  dedicated concierge service.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <img
              src={tool}
              alt="Find a home with our online search tool"
              className={classes.img}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom={true}>
              Simplified search
            </Typography>
            <Typography color="textSecondary">
              With a wealth of experience and knowledge across the real estate,
              education and leisure industry, WheresHome knows all there is to
              know to help you discover the perfect location for you and your
              family. From there, we can be as involved as you like. We will
              either simply connect you to all the local services you need or
              take care of it all with our dedicated VIP concierge service.
            </Typography>
            <Button
              style={{ marginTop: "16px" }}
              onClick={() => history.push("/")}
            >
              Find my next home
            </Button>
          </Grid>
        </Grid>
        <Box style={{ backgroundColor: "#F9F9F9" }} px={4}>
          <Grid
            container
            spacing={3}
            style={{
              marginTop: "50px",
              paddingTop: "25px",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h2" align="center" gutterBottom={true}>
                Concierge Services
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: "50px",
              paddingBottom: "25px",
            }}
          >
            <Grid item xs={12} md={4}>
              <Paper style={{ height: "100%", position: "relative" }}>
                <Box
                  p={3}
                  bgcolor="primary.main"
                  style={{
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ color: "#FFF" }}
                  >
                    Essentials Concierge Service
                  </Typography>
                </Box>
                <Box p={3}>
                  <Typography
                    variant="h4"
                    color="textSecondary"
                    align="center"
                    gutterBottom
                    style={{ marginBottom: "15px" }}
                  >
                    Prices on Request
                  </Typography>
                  <Typography
                    paragraph
                    style={{ height: mainParaHeight }}
                    color="textSecondary"
                  >
                    If the stress of the impending home search or home move has
                    you feeling a bit overwhelmed, having access to an astute
                    Whereshome team member to orchestrate the entire process
                    should brighten your spirits. Our goal is to put you
                    completely at ease so you can carry on with life as normal
                    while we take care of the not so fun aspects of your home
                    move.
                  </Typography>
                  <Typography
                    paragraph
                    style={{ marginBottom: "60px" }}
                    color="textSecondary"
                  >
                    <div style={{ height: setOtherHeight, fontWeight: "600" }}>
                      Our Essentials concierge service will:
                    </div>
                    <ul>
                      <li>
                        Understand and validate your requirements to help you
                        find the perfect location/property
                      </li>
                      <li>
                        Arrange viewings for shortlisted properties on your
                        behalf
                      </li>
                      <li>
                        Pair you with an independent buyer or executive
                        assistant dedicated to supporting you with your property
                        purchase or rental.
                      </li>
                      <li>
                        Moreover, you’ll be seamlessly connected with a trusted
                        and vetted specialist at every stage to assist with the
                        move, including mortgage brokers, surveyors, and
                        solicitors.
                      </li>
                      <li>
                        When it comes to coordinating the relocation of all your
                        precious belongings, we’ve got that covered too.
                      </li>
                    </ul>
                  </Typography>
                  <Button
                    style={{
                      marginTop: "16px",
                      position: "absolute",
                      width: "80%",
                      left: "10%",
                      right: "10%",
                      bottom: "20px",
                    }}
                    fullWidth
                    onClick={() => {
                      history.push("/contact/")
                      window.scrollTo(0, 0)
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper style={{ height: "100%", position: "relative" }}>
                <Box
                  p={3}
                  bgcolor="primary.main"
                  style={{
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ color: "#FFF" }}
                  >
                    Family Concierge Service
                  </Typography>
                </Box>
                <Box p={3}>
                  <Typography
                    variant="h4"
                    color="textSecondary"
                    align="center"
                    gutterBottom
                    style={{ marginBottom: "15px" }}
                  >
                    Prices on Request
                  </Typography>
                  <Typography
                    paragraph
                    style={{ height: mainParaHeight }}
                    color="textSecondary"
                  >
                    We understand the enormity of embarking on this journey with
                    a family and the added stress of having to arrange,
                    organise, and think for your children or wider family. Our
                    family concierge package has been carefully created with
                    these considerations in mind with expert knowledge on how to
                    flawlessly relocate a family and avoid any potential bumps
                    in the road.
                  </Typography>
                  <Typography
                    paragraph
                    style={{ marginBottom: "60px" }}
                    color="textSecondary"
                  >
                    <div style={{ height: setOtherHeight, fontWeight: "600" }}>
                      Our Family concierge Service includes the essentials
                      service plus:
                    </div>
                    <ul>
                      <li>
                        Carefully selecting a school based on your children and
                        their needs
                      </li>
                      <li>
                        Ensuring you meet the schools catchment area (If State
                        school)
                      </li>
                      <li>
                        Shortlisting and arranging tours for your preferred
                        schools.
                      </li>
                      <li>Handling the arduous admissions process.</li>
                    </ul>
                  </Typography>
                  <Button
                    style={{
                      marginTop: "16px",
                      position: "absolute",
                      width: "80%",
                      left: "10%",
                      right: "10%",
                      bottom: "20px",
                    }}
                    fullWidth
                    onClick={() => {
                      history.push("/contact/")
                      window.scrollTo(0, 0)
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper style={{ height: "100%", position: "relative" }}>
                <Box
                  p={3}
                  bgcolor="primary.main"
                  style={{
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ color: "#FFF" }}
                  >
                    VIP Concierge Service
                  </Typography>
                </Box>
                <Box p={3}>
                  <Typography
                    variant="h4"
                    color="textSecondary"
                    align="center"
                    gutterBottom
                    style={{ marginBottom: "15px" }}
                  >
                    Prices on Request
                  </Typography>
                  <Typography
                    paragraph
                    style={{ height: mainParaHeight }}
                    color="textSecondary"
                  >
                    If you don’t want to leave anything up to chance and would
                    much rather sit back and relax while the entire relocation
                    process and more is handled by our experienced team of
                    experts then our <b>bespoke VIP concierge service</b> is for
                    you.
                  </Typography>
                  <Typography
                    paragraph
                    style={{ marginBottom: "60px" }}
                    color="textSecondary"
                  >
                    Contact us for more details.
                  </Typography>
                  <Button
                    style={{
                      marginTop: "16px",
                      position: "absolute",
                      width: "80%",
                      left: "10%",
                      right: "10%",
                      bottom: "20px",
                    }}
                    fullWidth
                    onClick={() => {
                      history.push("/contact/")
                      window.scrollTo(0, 0)
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "50px",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h2" align="center" gutterBottom>
              Got more of an idea but still have questions?
            </Typography>
            <Box display="flex">
              <Box maxWidth="800px" className="mx-auto">
                <Typography align="center" paragraph color="textSecondary">
                  We hope you’ve got more of an idea of what’s involved in
                  relocating, but fear not if you’re still unsure - Our FREE 30
                  minute consultation call will help you get the clarity you’re
                  looking for on all aspects of your move, giving you confidence
                  on all aspects of the journey ahead!
                </Typography>
                <Box display="flex">
                  <Button className="mx-auto">
                    <a
                      href="https://calendly.com/whereshome/"
                      target="_blank"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Book FREE Consultation Call
                    </a>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          style={{
            marginTop: "100px",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h2" align="center" gutterBottom={true}>
              Our Partners
            </Typography>
          </Grid>
        </Grid>
        <Box display="flex" flexWrap="wrap" alignItems="center" mt={3} mb={12}>
          <Box className={classes.logoBox} px={2} py={2}>
            <img
              src={partnerLiquid}
              className={classes.partnerLogo}
              alt="Our partner Liquid Expat"
            />
          </Box>
          <Box className={classes.logoBox} px={2} py={2}>
            <img
              src={partnerPd}
              className={classes.partnerLogo}
              alt="Our partner PropertyData"
            />
          </Box>
          <Box className={classes.logoBox} px={2} py={2}>
            <img
              src={partnerDasa}
              className={classes.partnerLogo}
              alt="Our partner DASA"
            />
          </Box>
          <Box className={classes.logoBox} px={2} py={2}>
            <img
              src={partnerMc}
              className={classes.partnerLogo}
              alt="Our partner Mark Coysh"
            />
          </Box>
          <Box className={classes.logoBoxPush} px={2} py={2}>
            <img
              src={partnerPet}
              className={classes.partnerLogo}
              alt="Our partner Petshop Relocations"
            />
          </Box>
        </Box>
      </Box>
    </div>
  )
}
