import React from "react"
// Material UI
import { Chip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// styles
const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
}))

export default function CustomChip({ size, label, color }) {
  const classes = useStyles()
  return <Chip size={size} label={label} className={classes[color]} />
}
