import React from "react"
// Material UI
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// styles
const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  error: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))

export default function CustomButton({ size, text, color, onClick }) {
  const classes = useStyles()
  return (
    <Button size={size} className={classes[color]} onClick={onClick}>
      {text}
    </Button>
  )
}
