// Material UI
import { Grid, Paper, Box, Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Skeleton from "@material-ui/lab/Skeleton"
// Images
import oxford from "../../oxford.jpg"

// styles
const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: "cover",
    width: "100%",
    height: "175px",
    filter: "brightness(90%)"
  }
}))

export default function LoadingCard() {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper varian="outlined">
        <Skeleton variant="rect" height={175} />
        <Box p={2} pb={0}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Skeleton width={100} />
              </Typography>
              <Typography>
                <Skeleton width={70} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Skeleton width={110} />
              </Typography>
              <Typography>
                <Skeleton width={60} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Skeleton width={70} />
              </Typography>
              <Typography>
                <Skeleton width={95} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Skeleton width={75} />
              </Typography>
              <Typography>
                <Skeleton width={100} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={2}>
          <Skeleton variant="rect" height={36} />
        </Box>
      </Paper>
    </Grid>
  )
}
