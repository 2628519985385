import { createMuiTheme } from "@material-ui/core/styles"

//Creating theme
const originalTheme = createMuiTheme()
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#b2d492"
    },
    secondary: {
      main: "#38474e"
    },
    grey: {
      200: "#F2F2F2"
    }
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Newsreader", "serif"].join(","),
      fontWeight: "500",
      fontSize: "2.2rem",
      [originalTheme.breakpoints.down("md")]: {
        fontSize: "2.2rem"
      },
      [originalTheme.breakpoints.down("sm")]: {
        fontSize: "2.2rem"
      }
    },
    h2: {
      fontFamily: ["Newsreader", "serif"].join(","),
      fontWeight: "500",
      fontSize: "1.8rem",
      [originalTheme.breakpoints.down("md")]: {
        fontSize: "1.8rem"
      },
      [originalTheme.breakpoints.down("sm")]: {
        fontSize: "1.8rem"
      }
    },
    h3: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontWeight: "500",
      fontSize: "1.5rem",
      [originalTheme.breakpoints.down("md")]: {
        fontSize: "1.5rem"
      },
      [originalTheme.breakpoints.down("sm")]: {
        fontSize: "1.5rem"
      }
    },
    h4: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontWeight: "500",
      fontSize: "1.3rem",
      [originalTheme.breakpoints.down("md")]: {
        fontSize: "1.3rem"
      },
      [originalTheme.breakpoints.down("sm")]: {
        fontSize: "1.3rem"
      }
    },
    h5: {
      fontFamily: ["Nunito Sans", "sans-serif"].join(","),
      fontWeight: "500",
      fontSize: "1.1rem",
      [originalTheme.breakpoints.down("md")]: {
        fontSize: "1.1rem"
      },
      [originalTheme.breakpoints.down("sm")]: {
        fontSize: "1.1rem"
      }
    },
    h6: {
      fontFamily: ["Nunito Sans", "sans-serif"].join(","),
      fontWeight: "500",
      fontSize: "1rem",
      [originalTheme.breakpoints.down("md")]: {
        fontSize: "1rem"
      },
      [originalTheme.breakpoints.down("sm")]: {
        fontSize: "1rem"
      }
    },
    body1: {
      fontSize: "0.9rem",
      [originalTheme.breakpoints.down("md")]: {
        fontSize: "0.9rem"
      },
      [originalTheme.breakpoints.down("sm")]: {
        fontSize: "0.9rem"
      }
    },
    body2: {
      fontSize: "0.85rem",
      [originalTheme.breakpoints.down("md")]: {
        fontSize: "0.85rem"
      },
      [originalTheme.breakpoints.down("sm")]: {
        fontSize: "0.85rem"
      }
    }
  },
  // Props
  props: {
    MuiButton: {
      variant: "contained",
      color: "primary",
      disableElevation: true
    },
    MuiButtonGroup: {
      variant: "contained",
      disableElevation: true
    },
    MuiTextField: {
      autoComplete: "none",
      color: "secondary",
      fullWidth: true,
      size: "small",
      variant: "outlined"
    },
    MuiInput: {
      disableUnderline: true
    },
    MuiPaper: {
      variant: "elevation",
      elevation: 1
    },
    MuiLink: {
      variant: "body1"
    },
    MuiDataGrid: {
      disableSelectionOnClick: true,
      disableColumnMenu: true
    },
    MuiDialogTitle: {
      disableTypography: true
    },
    MuiDialog: {
      maxWidth: "xs"
    }
  },
  // Overrides
  overrides: {
    MuiInputBase: {
      root: {
        borderRadius: "2px"
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "2px"
      }
    },
    MuiDivider: {
      root: {
        marginBottom: originalTheme.spacing(2),
        marginTop: originalTheme.spacing(2)
      }
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "2px"
      },
      contained: {
        backgroundColor: "#fff"
      }
    },
    MuiTableCell: {
      root: {
        fontSize: "0.9rem"
      },
      sizeSmall: {
        padding: "6px 12px 6px 12px"
      }
    },
    MuiDataGrid: {
      root: {
        fontSize: "0.9rem",
        border: "none"
      }
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#000"
        }
      }
    },
    MuiDialog: {
      paperWidthXs: {
        width: "100%"
      }
    },
    MuiDialogTitle: {
      root: {
        fontSize: "1.2rem",
        fontWeight: "500",
        paddingTop: originalTheme.spacing(3)
      }
    },
    MuiDialogContentText: {
      root: {
        fontSize: "1rem"
      }
    },
    MuiDialogActions: {
      root: {
        paddingLeft: originalTheme.spacing(3),
        paddingRight: originalTheme.spacing(3),
        paddingBottom: originalTheme.spacing(3)
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "rgb(17 51 83 / 2%) 0px 4px 12px 0px",
        border: "1px solid rgb(236, 239, 241)"
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "14px"
      }
    },
    MuiTab: {
      root: {
        textTransform: "none",
        minWidth: "130px",
        [originalTheme.breakpoints.up("sm")]: {
          minWidth: "130px"
        }
      }
    },
    MuiContainer: {
      maxWidthLg: {
        [originalTheme.breakpoints.up("lg")]: {
          maxWidth: "1150px"
        }
      }
    }
  }
})

export { theme }
