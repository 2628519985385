import { useState, useEffect } from "react"
import axios from "axios"
// Router
import { useHistory } from "react-router-dom"
// Material UI
import { Grid, Box, Container, Typography, Paper } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { makeStyles } from "@material-ui/core/styles"

// styles
const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    height: "200px",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)"
    }
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "200px",
    filter: "brightness(90%)"
  },
  titleBox: {
    position: "absolute",
    bottom: "0",
    left: "0",
    background: "linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)",
    width: "calc(100% - 16px)",
    padding: "8px",
    minHeight: "50px",
    display: "flex",
    alignItems: "flex-end"
  },
  titleText: {
    color: "#FFF"
  }
}))

export default function Favourites() {
  const [towns, setTowns] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get("/locations/towns/?favorite=true&pageSize=1000")
      .then((response) => {
        setTowns(response.data.results)
        setLoading(false)
      })
  }, [])

  return (
    <Container maxWidth="lg">
      <Box py={10}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1" align="center" gutterBottom={true}>
              Whereshome favourites
            </Typography>
            <Box display="flex">
              <Box maxWidth="500px" className="mx-auto" mb={4}>
                <Typography color="textSecondary" align="center" variant="h5">
                  Stop worrying about what you should be looking for and where
                  you should be moving to and take a look at the most desirable
                  places to live in the UK right now.
                </Typography>
              </Box>
            </Box>
          </Grid>
          {loading ? (
            <>
              <LoadingLocation />
              <LoadingLocation />
              <LoadingLocation />
              <LoadingLocation />
              <LoadingLocation />
              <LoadingLocation />
            </>
          ) : (
            towns.map((town) => {
              return <SingleLocation town={town} />
            })
          )}
        </Grid>
      </Box>
    </Container>
  )
}

function SingleLocation({ town }) {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4}>
      <div
        className={classes.wrapper}
        onClick={() => history.push("/location/" + town.id)}
      >
        {town.image ? (
          <img src={town.image.url} alt={town.name} className={classes.img} />
        ) : (
          <img
            src="https://i.stack.imgur.com/y9DpT.jpg"
            alt={town.name}
            className={classes.img}
          />
        )}
        <div className={classes.titleBox}>
          <Typography className={classes.titleText} variant="h4">
            {town.name}
          </Typography>
        </div>
      </div>
    </Grid>
  )
}

function LoadingLocation() {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper varian="outlined">
        <Skeleton variant="rect" height={175} />
        <Box p={2} pb={0}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Skeleton width={100} />
              </Typography>
              <Typography>
                <Skeleton width={70} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Skeleton width={110} />
              </Typography>
              <Typography>
                <Skeleton width={60} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Skeleton width={70} />
              </Typography>
              <Typography>
                <Skeleton width={95} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Skeleton width={75} />
              </Typography>
              <Typography>
                <Skeleton width={100} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={2}>
          <Skeleton variant="rect" height={36} />
        </Box>
      </Paper>
    </Grid>
  )
}
