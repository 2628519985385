// Router
import { useHistory, useLocation } from "react-router-dom"
// Material UI
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  Tooltip
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import InfoIcon from "@material-ui/icons/Info"

// styles
const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    height: "175px"
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "175px",
    filter: "brightness(90%)"
  },
  titleBox: {
    position: "absolute",
    bottom: "0",
    left: "0",
    background: "linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)",
    width: "calc(100% - 16px)",
    padding: "8px",
    minHeight: "50px",
    display: "flex",
    alignItems: "flex-end"
  },
  titleText: {
    color: "#FFF"
  }
}))

export function formatPriceK(x) {
  if (x == null) return "-"
  try {
    x = Math.round(x / 1000)
    return "£" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "k"
  } catch {
    return "-"
  }
}
export function formatPrice(x) {
  if (x == null) return "-"
  try {
    return "£" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } catch {
    return "-"
  }
}

export function sqfPriceRange(town) {
  const min = formatPrice(town.averageHousePriceSqfMin)
  const max = formatPrice(town.averageHousePriceSqfMax)
  if (min && max && min != "-" && max != "-") {
    return min == max ? min : `${min} - ${max}`
  } else {
    return "Coming soon"
  }
}

export function priceRange(town, bedrooms, buying) {
  if (buying) {
    const min = formatPriceK(
      town[`averageHousePrice${bedrooms ? bedrooms : "All"}Min`]
    )
    const max = formatPriceK(
      town[`averageHousePrice${bedrooms ? bedrooms : "All"}Max`]
    )
    if (min && max && min != "-" && max != "-") {
      return min == max ? min : `${min} - ${max}`
    } else {
      return "Coming soon"
    }
  } else {
    const min = formatPrice(
      town[`averageRent${bedrooms ? bedrooms : "All"}Min`]
    )
    const max = formatPrice(
      town[`averageRent${bedrooms ? bedrooms : "All"}Max`]
    )
    if (min && max && min != "-" && max != "-") {
      return min == max ? min : `${min} - ${max}`
    } else {
      return "Coming soon"
    }
  }
}

export const crimeColors = {
  undefined: "common.black",
  "Very low crime": "success.main",
  "Low crime": "success.main",
  "Average crime": "warning.main",
  "High crime": "error.main"
}
export const schoolColors = {
  undefined: "common.black",
  "No schools": "error.main",
  "Poor schools": "error.main",
  "Average schools": "warning.main",
  "Good schools": "success.main",
  "Very good schools": "success.main"
}

export default function LocationCard({ bedrooms, town, buying }) {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper varian="outlined">
        <div className={classes.wrapper}>
          {town.image ? (
            <img src={town.image.url} alt={town.name} className={classes.img} />
          ) : (
            <img
              src="https://i.stack.imgur.com/y9DpT.jpg"
              alt={town.name}
              className={classes.img}
            />
          )}
          <div className={classes.titleBox}>
            <Typography className={classes.titleText} variant="h4">
              {town.name}
            </Typography>
          </div>
        </div>
        <Box p={2} pb={0}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Average price</Typography>
              <Typography>
                <strong>{priceRange(town, bedrooms, buying)}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Price per sqft</Typography>
              <Typography>
                <strong>{sqfPriceRange(town)}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex">
                <Typography variant="body2">Crime</Typography>
                <Tooltip
                  className="ml-auto"
                  title="Content to be provided for here."
                >
                  <InfoIcon style={{ fontSize: "1.05rem" }} color="disabled" />
                </Tooltip>
              </Box>
              <Box color={crimeColors[town.averageCrimeRating]}>
                <strong>
                  {town.averageCrimeRating
                    ? town.averageCrimeRating
                    : "Coming soon"}
                </strong>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex">
                <Typography variant="body2">Schools</Typography>
                <Tooltip
                  className="ml-auto"
                  title="Content to be provided for here."
                >
                  <InfoIcon style={{ fontSize: "1.05rem" }} color="disabled" />
                </Tooltip>
              </Box>
              <Box color={schoolColors[town.averageSchoolRating]}>
                <strong>
                  {town.averageSchoolRating &&
                  town.averageSchoolRating != "No schools"
                    ? town.averageSchoolRating
                    : "Coming soon"}
                </strong>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box p={2}>
          <Button
            fullWidth={true}
            onClick={() =>
              history.push({
                pathname: `/location/${town.id}/?bedrooms=${bedrooms}`,
                state: {
                  from: {
                    pathname: location.pathname,
                    search: location.search
                  }
                }
              })
            }
          >
            More Info
          </Button>
        </Box>
      </Paper>
    </Grid>
  )
}
