import React from "react"
// Material UI
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import {
  Publish as PublishIcon,
  Delete as DeleteIcon,
  GetApp as GetAppIcon,
} from "@material-ui/icons"

// styles
const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  list: {
    maxWidth: "350px",
  },
}))

export default function UploadButton() {
  const classes = useStyles()

  return (
    <Box>
      <input className={classes.input} id="uploadFile" multiple type="file" />
      <label htmlFor="uploadFile">
        <Button
          component="span"
          size="small"
          color="default"
          startIcon={<PublishIcon />}
        >
          Upload
        </Button>
      </label>
    </Box>
  )
}

export function CurrentUploadList() {
  const classes = useStyles()

  return (
    <Box>
      <List className={classes.list}>
        <Box bgcolor="grey.200">
          <ListItem>
            <ListItemText primary="uploadedfile.pdf" />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      </List>
    </Box>
  )
}

export function PreviouslyUploadedList() {
  const classes = useStyles()

  return (
    <Box>
      <List className={classes.list}>
        <Box bgcolor="grey.200">
          <ListItem>
            <ListItemText primary="uploadedfile.pdf" />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="download">
                <GetAppIcon color="info" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      </List>
    </Box>
  )
}

export function PreviouslyUploadedListDeletable() {
  const classes = useStyles()

  return (
    <Box>
      <List className={classes.list}>
        <Box bgcolor="grey.200">
          <ListItem>
            <ListItemText primary="uploadedfile.pdf" />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="download">
                <GetAppIcon color="info" />
              </IconButton>
              <IconButton edge="end" aria-label="download">
                <DeleteIcon color="info" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      </List>
    </Box>
  )
}
