import { useState, useContext, useEffect, useRef } from "react"
// Router
import { useHistory, useLocation } from "react-router-dom"
import { Auth, Hub, UserContext } from "../../cognito"
// Material UI
import { Box, TextField, Typography, Link, Button } from "@material-ui/core"
import { makeStyles, Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
// Components
import { Block } from "Components"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    width: "100%"
  },
  innerWrapper: {
    width: "100%",
    maxWidth: "400px"
  }
}))

export default function Login() {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const user = useContext(UserContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const loginResponse = useRef()
  const destination = useRef()

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("Authentication Failed")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (location.state && location.state.from && location.state.from.pathname) {
      destination.current = location.state.from.pathname
    } else {
      destination.current = "/admin"
    }
    if (user) {
      window.location = destination.current
    }
  }, [])

  async function confirmNewPassword() {
    Auth.completeNewPassword(loginResponse.current, newPassword).then(
      (response) => {
        window.location = destination.current
      }
    )
  }

  const signIn = async () => {
    setLoading(true)
    Auth.signIn(email, password)
      .then((response) => {
        setLoading(false)
        loginResponse.current = response
        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          setNewPasswordRequired(true)
        } else {
          window.location = destination.current
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        setSnackbarMsg(err.message)
        setSnackbarOpen(true)
      })
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.wrapper}
      >
        <Box className={classes.innerWrapper}>
          <Box>
            <Typography variant="h4" align="center" paragraph={true}>
              Admin Login
            </Typography>
            <Box mb={2}>
              <TextField
                variant="outlined"
                label="Email"
                className={classes.fullWidth}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Box>
            <Box mb={2}>
              <TextField
                variant="outlined"
                label="Password"
                className={classes.fullWidth}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                type="password"
              />
            </Box>
            {newPasswordRequired && (
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  label="New Password"
                  className={classes.fullWidth}
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                  type="password"
                />
              </Box>
            )}
            <Button
              color="primary"
              onClick={newPasswordRequired ? confirmNewPassword : signIn}
              disabled={loading}
              fullWidth={true}
            >
              Login
            </Button>
            <Box mt={2}>
              <Link
                display="block"
                align="center"
                onClick={() => history.push("/forgot-password")}
              >
                I forgot my password
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  )
}
